import {IBrassMp3, IBrassMp3Album, IBrassSheetMusic} from './brass';
import {IUser} from './user';
import {
  IContemporaryMp3,
  IContemporaryMp3Album,
  IContemporarySheetMusic,
} from './contemporary';
import {IVocalMp3, IVocalMp3Album, IVocalSheetMusic} from './vocal';

export interface ISalesUser {
  fname: string;
  lname: string;
  email: string;
}

export interface IBrassSalesData extends IBrassSheetMusicPurchaseDetail {
  purchase_time: string;
  stripe_token: string;
  user: ISalesUser;
}

export interface IBrassHardCopySalesData extends IBrassSalesData {
  num_copies: number;
}

export interface IBrassMp3SalesData extends IBrassMp3PurchaseDetail {
  purchase_time: string;
  stripe_token: string;
  user: ISalesUser;
}

export interface IBrassMp3AlbumSalesData extends IBrassMp3AlbumPurchaseDetail {
  purchase_time: string;
  stripe_token: string;
  user: ISalesUser;
}

export interface IContemporarySalesData
  extends IContemporarySheetMusicPurchaseDetail {
  purchase_time: string;
  stripe_token: string;
  user: ISalesUser;
}

export interface IContemporaryHardCopySalesData extends IContemporarySalesData {
  num_copies: number;
}

export interface IContemporaryMp3SalesData
  extends IContemporaryMp3PurchaseDetail {
  purchase_time: string;
  stripe_token: string;
  user: ISalesUser;
}

export interface IContemporaryMp3AlbumSalesData
  extends IContemporaryMp3AlbumPurchaseDetail {
  purchase_time: string;
  stripe_token: string;
  user: ISalesUser;
}

export interface IVocalSalesData extends IVocalSheetMusicPurchaseDetail {
  purchase_time: string;
  stripe_token: string;
  user: ISalesUser;
}

export interface IVocalHardCopySalesData extends IVocalSalesData {
  num_copies: number;
}

export interface IVocalMp3SalesData extends IVocalMp3PurchaseDetail {
  purchase_time: string;
  stripe_token: string;
  user: ISalesUser;
}

export interface IVocalMp3AlbumSalesData extends IVocalMp3AlbumPurchaseDetail {
  purchase_time: string;
  stripe_token: string;
  user: ISalesUser;
}

export interface ISubscriptionVoucherSalesData {
  amount_paid: number;
  brass_journals: Array<{
    name: string;
    price: number;
  }>;
  purchase_time: string;
  stripe_token: string;
  subscription_voucher_code: string;
  user: ISalesUser;
}

export interface ISalesData {
  brassHardCopyPurchases: IBrassHardCopySalesData[];
  brassMp3AlbumPurchases: IBrassMp3AlbumSalesData[];
  brassMp3Purchases: IBrassMp3SalesData[];
  brassPurchases: IBrassSalesData[];
  contemporaryHardCopyPurchases: IContemporaryHardCopySalesData[];
  contemporaryMp3AlbumPurchases: IContemporaryMp3AlbumSalesData[];
  contemporaryMp3Purchases: IContemporaryMp3SalesData[];
  contemporaryPurchases: IContemporarySalesData[];
  subscriptionVouchers?: ISubscriptionVoucherSalesData[];
  vocalHardCopyPurchases: IVocalHardCopySalesData[];
  vocalMp3AlbumPurchases: IVocalMp3AlbumSalesData[];
  vocalMp3Purchases: IVocalMp3SalesData[];
  vocalPurchases: IVocalSalesData[];
}

export interface ISalesOverview {
  commission: number;
  total: number;
}

export interface IBasicLogData {
  ip: number;
  os: string;
  browser: string;
  launch_method: string;
  user_id: number | null;
}

export interface ILogData extends IBasicLogData {
  user?: IUser;
}

export interface IBasicMediaLogType {
  id: number;
  name: string;
}

export interface IBasicSearchLogField {
  id: number;
  name: string;
}

export interface IBasicBrassDetailLog extends IBasicLogData {
  added_ts?: string;
  brass_piece_id: number;
  id: number;
  piece_info: string;
  user_name: string;
}

export interface IBrassDetailLog extends IBasicBrassDetailLog, ILogData {}

export interface IBasicBrassDigitisationRequestLog extends IBasicLogData {
  added_ts?: string;
  brass_publication_id: number;
  id: number;
  piece_info: string;
  user_name: string;
}

export interface IBrassDigitisationRequestLog
  extends IBasicBrassDigitisationRequestLog,
    ILogData {}

export interface IBasicBrassHardCopyRequestLog extends IBasicLogData {
  added_ts?: string;
  brass_publication_id: number;
  id: number;
  piece_info: string;
  user_name: string;
}

export interface IBrassHardCopyRequestLog
  extends IBasicBrassHardCopyRequestLog,
    ILogData {}

export interface IBasicBrassMediaLog extends IBasicLogData {
  added_ts?: string;
  brass_piece_id: number;
  id: number;
  media_log_type_id: number;
  piece_info: string;
  type: 'audio' | 'video';
  user_name: string;
}

export interface IBrassMediaLog extends IBasicBrassMediaLog, ILogData {
  mediaLogType: IBasicMediaLogType;
}

export interface IBasicBrassPrintLog extends IBasicLogData {
  added_ts?: string;
  brass_sheet_music_id: number;
  id: number;
  part: string;
  piece_info: string;
  user_name: string;
}

export interface IBrassPrintLog extends IBasicBrassPrintLog, ILogData {}

export interface IBasicBrassScoreNotesLog extends IBasicLogData {
  added_ts?: string;
  brass_publication_id: number;
  id: number;
  piece_info: string;
  score_notes_available: number;
  user_name: string;
}

export interface IBrassScoreNotesLog
  extends IBasicBrassScoreNotesLog,
    ILogData {}

export interface IBasicBrassScoreNotesRequestLog extends IBasicLogData {
  added_ts?: string;
  brass_publication_id: number;
  id: number;
  piece_info: string;
  user_name: string;
}

export interface IBrassScoreNotesRequestLog
  extends IBasicBrassScoreNotesRequestLog,
    ILogData {}

export interface IBasicBrassSearchLog extends IBasicLogData {
  added_ts?: string;
  field: string;
  id: number;
  search_log_field_id: number;
  user_name: string;
  value: string;
}

export interface IBrassSearchLog extends IBasicBrassSearchLog, ILogData {
  searchLogField: IBasicSearchLogField;
}

export interface IBasicBrassSeriesDetailLog extends IBasicLogData {
  added_ts?: string;
  brass_series_id: number;
  id: number;
  series_info: string;
  user_name: string;
}

export interface IBrassSeriesDetailLog
  extends IBasicBrassSeriesDetailLog,
    ILogData {}

export interface IBasicBrassSheetMusicLog extends IBasicLogData {
  added_ts?: string;
  brass_sheet_music_id: number;
  id: number;
  part: string;
  piece_info: string;
  user_name: string;
}

export interface IBrassSheetMusicLog
  extends IBasicBrassSheetMusicLog,
    ILogData {}

export interface IBasicContemporaryDetailLog extends IBasicLogData {
  added_ts?: string;
  contemporary_piece_id: number;
  id: number;
  piece_info: string;
  user_name: string;
}

export interface IContemporaryDetailLog
  extends IBasicContemporaryDetailLog,
    ILogData {}

export interface IBasicContemporaryDigitisationRequestLog
  extends IBasicLogData {
  added_ts?: string;
  contemporary_publication_id: number;
  id: number;
  piece_info: string;
  user_name: string;
}

export interface IContemporaryDigitisationRequestLog
  extends IBasicContemporaryDigitisationRequestLog,
    ILogData {}

export interface IBasicContemporaryHardCopyRequestLog extends IBasicLogData {
  added_ts?: string;
  contemporary_publication_id: number;
  id: number;
  piece_info: string;
  user_name: string;
}

export interface IContemporaryHardCopyRequestLog
  extends IBasicContemporaryHardCopyRequestLog,
    ILogData {}

export interface IBasicContemporaryMediaLog extends IBasicLogData {
  added_ts?: string;
  contemporary_piece_id: number;
  id: number;
  media_log_type_id: number;
  piece_info: string;
  type: 'audio' | 'video';
  user_name: string;
}

export interface IContemporaryMediaLog
  extends IBasicContemporaryMediaLog,
    ILogData {
  mediaLogType: IBasicMediaLogType;
}

export interface IBasicContemporaryPrintLog extends IBasicLogData {
  added_ts?: string;
  contemporary_sheet_music_id: number;
  id: number;
  part: string;
  piece_info: string;
  user_name: string;
}

export interface IContemporaryPrintLog
  extends IBasicContemporaryPrintLog,
    ILogData {}

export interface IBasicContemporaryScoreNotesLog extends IBasicLogData {
  added_ts?: string;
  contemporary_publication_id: number;
  id: number;
  piece_info: string;
  score_notes_available: number;
  user_name: string;
}

export interface IContemporaryScoreNotesLog
  extends IBasicContemporaryScoreNotesLog,
    ILogData {}

export interface IBasicContemporaryScoreNotesRequestLog extends IBasicLogData {
  added_ts?: string;
  contemporary_publication_id: number;
  id: number;
  piece_info: string;
  user_name: string;
}

export interface IContemporaryScoreNotesRequestLog
  extends IBasicContemporaryScoreNotesRequestLog,
    ILogData {}

export interface IBasicContemporarySearchLog extends IBasicLogData {
  added_ts?: string;
  field: string;
  id: number;
  search_log_field_id: number;
  user_name: string;
  value: string;
}

export interface IContemporarySearchLog
  extends IBasicContemporarySearchLog,
    ILogData {
  searchLogField: IBasicSearchLogField;
}

export interface IBasicContemporarySeriesDetailLog extends IBasicLogData {
  added_ts?: string;
  contemporary_series_id: number;
  id: number;
  series_info: string;
  user_name: string;
}

export interface IContemporarySeriesDetailLog
  extends IBasicContemporarySeriesDetailLog,
    ILogData {}

export interface IBasicContemporarySheetMusicLog extends IBasicLogData {
  added_ts?: string;
  contemporary_sheet_music_id: number;
  id: number;
  part: string;
  piece_info: string;
  user_name: string;
}

export interface IBasicVocalDetailLog extends IBasicLogData {
  added_ts?: string;
  id: number;
  piece_info: string;
  user_name: string;
  vocal_piece_id: number;
}

export interface IVocalDetailLog extends IBasicVocalDetailLog, ILogData {}

export interface IBasicVocalDigitisationRequestLog extends IBasicLogData {
  added_ts?: string;
  vocal_publication_id: number;
  id: number;
  piece_info: string;
  user_name: string;
}

export interface IVocalDigitisationRequestLog
  extends IBasicVocalDigitisationRequestLog,
    ILogData {}

export interface IBasicVocalHardCopyRequestLog extends IBasicLogData {
  added_ts?: string;
  vocal_publication_id: number;
  id: number;
  piece_info: string;
  user_name: string;
}

export interface IVocalHardCopyRequestLog
  extends IBasicVocalHardCopyRequestLog,
    ILogData {}

export interface IBasicVocalMediaLog extends IBasicLogData {
  added_ts?: string;
  id: number;
  media_log_type_id: number;
  piece_info: string;
  type: 'audio' | 'video';
  user_name: string;
  vocal_piece_id: number;
}

export interface IVocalMediaLog extends IBasicVocalMediaLog, ILogData {
  mediaLogType: IBasicMediaLogType;
}

export interface IBasicVocalPrintLog extends IBasicLogData {
  added_ts?: string;
  id: number;
  part: string;
  piece_info: string;
  user_name: string;
  vocal_sheet_music_id: number;
}

export interface IVocalPrintLog extends IBasicVocalPrintLog, ILogData {}

export interface IBasicVocalScoreNotesLog extends IBasicLogData {
  added_ts?: string;
  vocal_publication_id: number;
  id: number;
  piece_info: string;
  score_notes_available: number;
  user_name: string;
}

export interface IVocalScoreNotesLog
  extends IBasicVocalScoreNotesLog,
    ILogData {}

export interface IBasicVocalScoreNotesRequestLog extends IBasicLogData {
  added_ts?: string;
  vocal_publication_id: number;
  id: number;
  piece_info: string;
  user_name: string;
}

export interface IVocalScoreNotesRequestLog
  extends IBasicVocalScoreNotesRequestLog,
    ILogData {}

export interface IBasicVocalSearchLog extends IBasicLogData {
  added_ts?: string;
  field: string;
  id: number;
  search_log_field_id: number;
  user_name: string;
  value: string;
}

export interface IVocalSearchLog extends IBasicVocalSearchLog, ILogData {
  searchLogField: IBasicSearchLogField;
}

export interface IBasicVocalSeriesDetailLog extends IBasicLogData {
  added_ts?: string;
  vocal_series_id: number;
  id: number;
  series_info: string;
  user_name: string;
}

export interface IVocalSeriesDetailLog
  extends IBasicVocalSeriesDetailLog,
    ILogData {}

export interface IBasicVocalSheetMusicLog extends IBasicLogData {
  added_ts?: string;
  id: number;
  part: string;
  piece_info: string;
  user_name: string;
  vocal_sheet_music_id: number;
}

export interface IBasicRegistration {
  name: string;
  email: string;
  added_ts?: string;
}

export interface IVocalSheetMusicLog
  extends IBasicVocalSheetMusicLog,
    ILogData {}

export type StatsKey =
  | 'brassDetail'
  | 'brassDigitisationRequest'
  | 'brassHardCopyPurchase'
  | 'brassHardCopyRequest'
  | 'brassMedia'
  | 'brassMp3AlbumPurchase'
  | 'brassMp3Purchase'
  | 'brassPrint'
  | 'brassPurchase'
  | 'brassScoreNotesRequest'
  | 'brassScoreNotesView'
  | 'brassSearch'
  | 'brassSeriesDetail'
  | 'brassSheetMusic'
  | 'contemporaryDetail'
  | 'contemporaryHardCopyPurchase'
  | 'contemporaryMedia'
  | 'contemporaryMp3AlbumPurchase'
  | 'contemporaryMp3Purchase'
  | 'contemporaryPrint'
  | 'contemporaryPurchase'
  | 'contemporaryScoreNotesView'
  | 'contemporarySearch'
  | 'contemporarySeriesDetail'
  | 'contemporarySheetMusic'
  | 'registration'
  | 'vocalDetail'
  | 'vocalDigitisationRequest'
  | 'vocalHardCopyPurchase'
  | 'vocalHardCopyRequest'
  | 'vocalMedia'
  | 'vocalMp3AlbumPurchase'
  | 'vocalMp3Purchase'
  | 'vocalPrint'
  | 'vocalPurchase'
  | 'vocalScoreNotesRequest'
  | 'vocalScoreNotesView'
  | 'vocalSearch'
  | 'vocalSeriesDetail'
  | 'vocalSheetMusic';

type IStatsOverview = {[K in StatsKey]: number};

export interface IStatsData {
  overview: IStatsOverview;
  brassDetail: IBasicBrassDetailLog[];
  brassDigitisationRequest: IBasicBrassDigitisationRequestLog[];
  brassHardCopyPurchase?: null; // Don't need this as it's in the sales report
  brassHardCopyRequest: IBasicBrassHardCopyRequestLog[];
  brassMedia: IBasicBrassMediaLog[];
  brassMp3AlbumPurchase?: null; // Don't need this as it's in the sales report
  brassMp3Purchase?: null; // Don't need this as it's in the sales report
  brassPrint: IBasicBrassPrintLog[];
  brassPurchase?: null; // Don't need this as it's in the sales report
  brassScoreNotesRequest: IBasicBrassScoreNotesRequestLog[];
  brassScoreNotesView: IBasicBrassScoreNotesLog[];
  brassSearch: IBasicBrassSearchLog[];
  brassSeriesDetail: IBasicBrassSeriesDetailLog[];
  brassSheetMusic: IBasicBrassSheetMusicLog[];
  contemporaryDetail: IBasicContemporaryDetailLog[];
  contemporaryHardCopyPurchase?: null; // Don't need this as it's in the sales report
  contemporaryMedia: IBasicContemporaryMediaLog[];
  contemporaryMp3AlbumPurchase?: null; // Don't need this as it's in the sales report
  contemporaryMp3Purchase?: null; // Don't need this as it's in the sales report
  contemporaryPrint: IBasicContemporaryPrintLog[];
  contemporaryPurchase?: null; // Don't need this as it's in the sales report
  contemporaryScoreNotesView: IBasicContemporaryScoreNotesLog[];
  contemporarySearch: IBasicContemporarySearchLog[];
  contemporarySeriesDetail: IBasicContemporarySeriesDetailLog[];
  contemporarySheetMusic: IBasicContemporarySheetMusicLog[];
  registration: IBasicRegistration[];
  vocalDetail: IBasicVocalDetailLog[];
  vocalDigitisationRequest: IBasicVocalDigitisationRequestLog[];
  vocalHardCopyPurchase?: null; // Don't need this as it's in the sales report
  vocalHardCopyRequest: IBasicVocalHardCopyRequestLog[];
  vocalMedia: IBasicVocalMediaLog[];
  vocalMp3AlbumPurchase?: null; // Don't need this as it's in the sales report
  vocalMp3Purchase?: null; // Don't need this as it's in the sales report
  vocalPrint: IBasicVocalPrintLog[];
  vocalPurchase?: null; // Don't need this as it's in the sales report
  vocalScoreNotesRequest: IBasicVocalScoreNotesRequestLog[];
  vocalScoreNotesView: IBasicVocalScoreNotesLog[];
  vocalSearch: IBasicVocalSearchLog[];
  vocalSeriesDetail: IBasicVocalSeriesDetailLog[];
  vocalSheetMusic: IBasicVocalSheetMusicLog[];
}

export type Frequency = 'Y' | 'M' | 'W' | 'D';

export interface IChartPoint {
  period: string;
  total: number;
}

export interface IChartsData {
  detail: IChartPoint[];
  digitisationRequest: IChartPoint[];
  hardCopyPurchase: IChartPoint[];
  hardCopyRequest: IChartPoint[];
  media: IChartPoint[];
  mp3AlbumPurchase: IChartPoint[];
  mp3Purchase: IChartPoint[];
  print: IChartPoint[];
  purchase: IChartPoint[];
  registration: IChartPoint[];
  revenue: IChartPoint[];
  scoreNotes: IChartPoint[];
  scoreNotesRequest: IChartPoint[];
  search: IChartPoint[];
  seriesDetail: IChartPoint[];
  sheetMusic: IChartPoint[];
}

export interface IBrassJournal {
  id: number;
  name: string;
  price: number;
  year: number;
}

export interface IJournalsData {
  brassJournals: IBrassJournal[];
}

export interface ISubscriptionVoucher {
  applied: boolean;
  brassJournals: IBrassJournal[];
  id: number;
  code: string;
  customer_name: string;
  email: string;
  sequence_number: string;
}

export type IVouchersData = ISubscriptionVoucher[];

export interface IBasicBrassPurchaseDetail {
  amount_paid: number;
  brass_sheet_music_id: number;
  id: number;
  view_count?: number;
  download_count?: number;
  purchase_time: string;
  receipt_url: string;
  stripe_token?: string;
  user_id: number;
  voucher_code: string | null;
}

export interface IBrassSheetMusicPurchaseDetail
  extends IBasicBrassPurchaseDetail {
  [key: string]:
    | IBrassSheetMusic
    | ISalesUser
    | number
    | string
    | null
    | undefined;
  brassSheetMusic: IBrassSheetMusic;
}

export interface IBasicContemporaryPurchaseDetail {
  amount_paid: number;
  contemporary_sheet_music_id: number;
  id: number;
  view_count?: number;
  download_count?: number;
  purchase_time: string;
  receipt_url: string;
  stripe_token?: string;
  user_id: number;
  voucher_code: string | null;
}

export interface IContemporarySheetMusicPurchaseDetail
  extends IBasicContemporaryPurchaseDetail {
  contemporarySheetMusic: IContemporarySheetMusic;
}

export interface IBasicVocalPurchaseDetail {
  amount_paid: number;
  id: number;
  view_count?: number;
  download_count?: number;
  purchase_time: string;
  receipt_url: string;
  stripe_token?: string;
  user_id: number;
  vocal_sheet_music_id: number;
  voucher_code: string | null;
}

export interface IVocalSheetMusicPurchaseDetail
  extends IBasicVocalPurchaseDetail {
  vocalSheetMusic: IVocalSheetMusic;
}

export interface IBasicBrassMp3AlbumPurchaseDetail {
  amount_paid: number;
  brass_mp3_album_id: number;
  id: number;
  download_count: number;
  purchase_time: string;
  receipt_url: string;
  stripe_token?: string;
  user_id: number;
}

export interface IBrassMp3AlbumPurchaseDetail
  extends IBasicBrassMp3AlbumPurchaseDetail {
  brassMp3Album: IBrassMp3Album;
}

export interface IBasicContemporaryMp3AlbumPurchaseDetail {
  amount_paid: number;
  id: string;
  download_count: string;
  purchase_time: string;
  receipt_url: string;
  stripe_token: string;
  user_id: string;
  contemporary_mp3_album_id: string;
}

export interface IContemporaryMp3AlbumPurchaseDetail
  extends IBasicContemporaryMp3AlbumPurchaseDetail {
  contemporaryMp3Album: IContemporaryMp3Album;
}

export interface IBasicVocalMp3AlbumPurchaseDetail {
  amount_paid: number;
  id: string;
  download_count: string;
  purchase_time: string;
  receipt_url: string;
  stripe_token: string;
  user_id: string;
  vocal_mp3_album_id: string;
}

export interface IVocalMp3AlbumPurchaseDetail
  extends IBasicVocalMp3AlbumPurchaseDetail {
  vocalMp3Album: IVocalMp3Album;
}

export interface IBasicBrassMp3PurchaseDetail {
  amount_paid: number;
  brass_mp3_id: number;
  id: number;
  download_count: number;
  purchase_time: string;
  receipt_url: string;
  stripe_token?: string;
  user_id: number;
}

export interface IBrassMp3PurchaseDetail extends IBasicBrassMp3PurchaseDetail {
  brassMp3: IBrassMp3;
}

export interface IBasicContemporaryMp3PurchaseDetail {
  amount_paid: number;
  id: number;
  download_count: number;
  purchase_time: string;
  receipt_url: string;
  stripe_token?: string;
  user_id: number;
  contemporary_mp3_id: number;
}

export interface IContemporaryMp3PurchaseDetail
  extends IBasicContemporaryMp3PurchaseDetail {
  contemporaryMp3: IContemporaryMp3;
}

export interface IBasicVocalMp3PurchaseDetail {
  amount_paid: number;
  id: number;
  download_count: number;
  purchase_time: string;
  receipt_url: string;
  stripe_token?: string;
  user_id: number;
  vocal_mp3_id: number;
}

export interface IVocalMp3PurchaseDetail extends IBasicVocalMp3PurchaseDetail {
  vocalMp3: IVocalMp3;
}

export type IMinComposersData = Record<
  number,
  {
    /** First Name */
    f: string;
    /** Last Name */
    l: string;
  }
>;

export interface IComposerRow {
  getDisplayValue: () => string;
  composerId: number;
}

export class ComposerRow implements IComposerRow {
  public composerId: number;
  private firstName: string;
  private lastName: string;
  public constructor(options: {
    firstName: string;
    lastName: string;
    composerId: number;
  }) {
    this.firstName = options.firstName;
    this.lastName = options.lastName;
    this.composerId = options.composerId;
  }

  public getDisplayValue() {
    return this.firstName
      ? `${this.firstName} ${this.lastName}`
      : this.lastName;
  }
}
