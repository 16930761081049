import removeAccents from 'remove-accents';
import {
  ContemporaryPublicationRow,
  ContemporarySort,
  IContemporaryFilters,
  IContemporaryMp3Album,
  IContemporaryPublicationRow,
  IContemporaryRow,
  IContemporarySeriesDropdown,
  IMinContemporaryData,
  IMinContemporaryDetail,
  IMinContemporaryPublication,
  IMinContemporarySeriesData,
} from '../../definitions/contemporary';
import {IMinSeriesDetail} from '../../definitions/shared';
import sortBy from '../../utils/sortBy';
import textCompare from '../../utils/textCompare';
import {
  FETCH_CONTEMPORARY_DETAIL_ERROR,
  FETCH_CONTEMPORARY_DETAIL_RECEIVE,
  FETCH_CONTEMPORARY_DETAIL_STARTED,
  FETCH_CONTEMPORARY_GRID_DATA_ERROR,
  FETCH_CONTEMPORARY_GRID_DATA_RECEIVE,
  FETCH_CONTEMPORARY_GRID_DATA_STARTED,
  FETCH_CONTEMPORARY_MP3_ALBUM_ERROR,
  FETCH_CONTEMPORARY_MP3_ALBUM_RECEIVE,
  FETCH_CONTEMPORARY_MP3_ALBUM_STARTED,
  FETCH_CONTEMPORARY_SERIES_ERROR,
  FETCH_CONTEMPORARY_SERIES_RECEIVE,
  FETCH_CONTEMPORARY_SERIES_STARTED,
  FETCH_CONTEMPORARY_SERIES_DETAIL_ERROR,
  FETCH_CONTEMPORARY_SERIES_DETAIL_RECEIVE,
  FETCH_CONTEMPORARY_SERIES_DETAIL_STARTED,
  FILTER_CONTEMPORARY,
  FILTER_CONTEMPORARY_EXECUTE,
  FILTER_CONTEMPORARY_PRIME,
  FILTER_CONTEMPORARY_RESET,
  RESET_CONTEMPORARY_DETAIL,
  RESET_CONTEMPORARY_MP3_ALBUM,
  RESET_CONTEMPORARY_SERIES_DETAIL,
  SORT_CONTEMPORARY,
  SORT_CONTEMPORARY_EXECUTE,
  SORT_CONTEMPORARY_PRIME,
  UPDATE_CONTEMPORARY_DETAIL_INDEX,
  FETCH_CONTEMPORARY_PUBLICATION_DATA_STARTED,
  FETCH_CONTEMPORARY_PUBLICATION_DATA_RECEIVE,
  FETCH_CONTEMPORARY_PUBLICATION_DATA_ERROR,
} from '../actions';
import {IAction} from '../store';

export interface IContemporaryState {
  contemporaryDetailData: {
    detail: IMinContemporaryDetail;
    error: boolean;
    fetching: boolean;
    index: number;
  };
  contemporaryFilters: IContemporaryFilters;
  contemporaryGridData: {
    detail: IContemporaryRow[];
    error: boolean;
    fetched: boolean;
    fetching: boolean;
    full: IContemporaryRow[];
  };
  contemporaryMp3AlbumData: {
    detail?: IContemporaryMp3Album;
    error: boolean;
    fetching: boolean;
  };
  contemporaryPublicationData: {
    detail: IContemporaryPublicationRow[];
    error: boolean;
    fetching: boolean;
  };
  contemporarySeriesData: {
    detail: IContemporarySeriesDropdown[];
    error: boolean;
    fetching: boolean;
  };
  contemporarySeriesDetailData: {
    detail: IMinSeriesDetail;
    error: boolean;
    fetching: boolean;
  };
  contemporarySort: ContemporarySort;
}

export const initialState: IContemporaryState = {
  contemporaryDetailData: {
    detail: {
      a: null,
      aid: null,
      c: null,
      cid: null,
      cr: null,
      do: null,
      dt: null,
      i: null,
      id: null,
      l: null,
      lid: null,
      mp3: [],
      n: null,
      noa: null,
      noc: null,
      nop: null,
      pid: null,
      rz: false,
      s: null,
      sid: null,
      sh: [],
      tt: null,
      vd: null,
      vm: null,
      ybs: null,
      yt: null,
    },
    error: false,
    fetching: false,
    index: 0,
  },
  contemporaryFilters: {
    issue: '',
    lyricist: '',
    media: false,
    series: '',
    sheetMusic: false,
    title: '',
    year: '',
  },
  contemporaryGridData: {
    detail: [],
    error: false,
    fetched: false,
    fetching: false,
    full: [],
  },
  contemporaryMp3AlbumData: {
    error: false,
    fetching: false,
  },
  contemporaryPublicationData: {
    detail: [],
    error: false,
    fetching: false,
  },
  contemporarySeriesData: {
    detail: [],
    error: false,
    fetching: false,
  },
  contemporarySeriesDetailData: {
    detail: {
      id: null,
      d: null,
      fy: null,
      ly: null,
      n: null,
      p: null,
    },
    error: false,
    fetching: false,
  },
  contemporarySort: 'default',
};

export default function contemporaryReducer(
  state = initialState,
  action: IAction,
): IContemporaryState {
  switch (action.type) {
    case FETCH_CONTEMPORARY_GRID_DATA_STARTED:
      return {
        ...state,
        contemporaryGridData: {
          ...state.contemporaryGridData,
          error: false,
          fetching: true,
        },
      };

    case FETCH_CONTEMPORARY_GRID_DATA_RECEIVE: {
      const gridData = prepareData(action.data);
      return {
        ...state,
        contemporaryGridData: {
          detail: sortData(state.contemporarySort, gridData),
          error: false,
          fetched: true,
          fetching: false,
          full: gridData,
        },
      };
    }

    case FETCH_CONTEMPORARY_GRID_DATA_ERROR:
      return {
        ...state,
        contemporaryGridData: {
          ...state.contemporaryGridData,
          error: true,
          fetching: false,
        },
      };

    case FETCH_CONTEMPORARY_DETAIL_STARTED:
      return {
        ...state,
        contemporaryDetailData: {
          ...state.contemporaryDetailData,
          error: false,
          fetching: true,
        },
      };

    case FETCH_CONTEMPORARY_DETAIL_RECEIVE:
      return {
        ...state,
        contemporaryDetailData: {
          ...state.contemporaryDetailData,
          detail: action.data,
          error: false,
          fetching: false,
        },
      };

    case FETCH_CONTEMPORARY_DETAIL_ERROR:
      return {
        ...state,
        contemporaryDetailData: {
          ...state.contemporaryDetailData,
          error: true,
          fetching: false,
        },
      };

    case FETCH_CONTEMPORARY_MP3_ALBUM_STARTED:
      return {
        ...state,
        contemporaryMp3AlbumData: {
          ...state.contemporaryMp3AlbumData,
          error: false,
          fetching: true,
        },
      };

    case FETCH_CONTEMPORARY_MP3_ALBUM_RECEIVE:
      return {
        ...state,
        contemporaryMp3AlbumData: {
          ...state.contemporaryMp3AlbumData,
          detail: action.data,
          error: false,
          fetching: false,
        },
      };

    case FETCH_CONTEMPORARY_MP3_ALBUM_ERROR:
      return {
        ...state,
        contemporaryMp3AlbumData: {
          ...state.contemporaryMp3AlbumData,
          error: true,
          fetching: false,
        },
      };

    case FETCH_CONTEMPORARY_SERIES_STARTED:
      return {
        ...state,
        contemporarySeriesData: {
          ...state.contemporarySeriesData,
          error: false,
          fetching: true,
        },
      };

    case FETCH_CONTEMPORARY_SERIES_RECEIVE: {
      const seriesData = formatSeriesData(action.data);
      return {
        ...state,
        contemporarySeriesData: {
          detail: seriesData,
          error: false,
          fetching: false,
        },
      };
    }

    case FETCH_CONTEMPORARY_SERIES_ERROR:
      return {
        ...state,
        contemporarySeriesData: {
          ...state.contemporarySeriesData,
          error: true,
          fetching: false,
        },
      };

    case RESET_CONTEMPORARY_DETAIL:
      return {
        ...state,
        contemporaryDetailData: {
          ...state.contemporaryDetailData,
          detail: initialState.contemporaryDetailData.detail,
        },
      };

    case RESET_CONTEMPORARY_MP3_ALBUM:
      return {
        ...state,
        contemporaryMp3AlbumData: {
          ...state.contemporaryMp3AlbumData,
          detail: undefined,
        },
      };

    case FILTER_CONTEMPORARY: {
      const contemporaryFilters = getNewContemporaryFilters(state, action);
      return {
        ...state,
        contemporaryFilters,
        contemporaryGridData: {
          ...state.contemporaryGridData,
          detail: sortData(
            state.contemporarySort,
            filterData(contemporaryFilters, state.contemporaryGridData.full),
          ),
        },
      };
    }

    case FILTER_CONTEMPORARY_PRIME: {
      const contemporaryFilters = getNewContemporaryFilters(state, action);
      return {
        ...state,
        contemporaryFilters,
      };
    }

    case FILTER_CONTEMPORARY_EXECUTE:
      return {
        ...state,
        contemporaryGridData: {
          ...state.contemporaryGridData,
          detail: sortData(
            state.contemporarySort,
            filterData(
              state.contemporaryFilters,
              state.contemporaryGridData.full,
            ),
          ),
        },
      };

    case FILTER_CONTEMPORARY_RESET: {
      const resetContemporaryFilters = getResetContemporaryFilters();
      const resetSort = 'default';
      return {
        ...state,
        contemporaryDetailData: {
          ...state.contemporaryDetailData,
          index: 0,
        },
        contemporaryFilters: resetContemporaryFilters,
        contemporaryGridData: {
          ...state.contemporaryGridData,
          detail: sortData(
            resetSort,
            filterData(
              resetContemporaryFilters,
              state.contemporaryGridData.full,
            ),
          ),
        },
        contemporarySort: resetSort,
      };
    }

    case SORT_CONTEMPORARY: {
      return {
        ...state,
        contemporaryGridData: {
          ...state.contemporaryGridData,
          detail: sortData(
            action.contemporarySort,
            state.contemporaryGridData.detail,
          ),
        },
        contemporarySort: action.contemporarySort,
      };
    }

    case SORT_CONTEMPORARY_PRIME: {
      return {
        ...state,
        contemporarySort: action.contemporarySort,
      };
    }

    case SORT_CONTEMPORARY_EXECUTE:
      return {
        ...state,
        contemporaryGridData: {
          ...state.contemporaryGridData,
          detail: sortData(
            state.contemporarySort,
            state.contemporaryGridData.detail,
          ),
        },
      };

    case UPDATE_CONTEMPORARY_DETAIL_INDEX:
      return {
        ...state,
        contemporaryDetailData: {
          ...state.contemporaryDetailData,
          index: action.index,
        },
      };

    case FETCH_CONTEMPORARY_PUBLICATION_DATA_STARTED:
      return {
        ...state,
        contemporaryPublicationData: {
          ...state.contemporaryPublicationData,
          error: false,
          fetching: true,
        },
      };

    case FETCH_CONTEMPORARY_PUBLICATION_DATA_RECEIVE: {
      const contemporaryPublicationData = preparePublicationData(
        state.contemporaryGridData.detail,
      );
      return {
        ...state,
        contemporaryPublicationData: {
          detail: contemporaryPublicationData,
          error: false,
          fetching: false,
        },
      };
    }

    case FETCH_CONTEMPORARY_PUBLICATION_DATA_ERROR:
      return {
        ...state,
        contemporaryPublicationData: {
          ...state.contemporaryPublicationData,
          error: true,
          fetching: false,
        },
      };

    case FETCH_CONTEMPORARY_SERIES_DETAIL_STARTED:
      return {
        ...state,
        contemporarySeriesDetailData: {
          ...state.contemporarySeriesDetailData,
          error: false,
          fetching: true,
        },
      };

    case FETCH_CONTEMPORARY_SERIES_DETAIL_RECEIVE:
      return {
        ...state,
        contemporarySeriesDetailData: {
          ...state.contemporarySeriesDetailData,
          detail: action.data,
          error: false,
          fetching: false,
        },
      };

    case FETCH_CONTEMPORARY_SERIES_DETAIL_ERROR:
      return {
        ...state,
        contemporarySeriesDetailData: {
          ...state.contemporarySeriesDetailData,
          error: true,
          fetching: false,
        },
      };

    case RESET_CONTEMPORARY_SERIES_DETAIL:
      return {
        ...state,
        contemporarySeriesDetailData: {
          ...state.contemporarySeriesDetailData,
          detail: initialState.contemporarySeriesDetailData.detail,
        },
      };

    default:
      return state;
  }
}

function prepareData(
  contemporaryData: IMinContemporaryData,
): IContemporaryRow[] {
  if (contemporaryData === undefined) {
    return [];
  }

  return contemporaryData.pb.map((publication) => {
    return {
      arranger: getArrangerCellContent(publication, contemporaryData),
      composer: getComposerCellContent(publication, contemporaryData),
      issue: getIssueCellContent(publication),
      lyricist: getLyricistCellContent(publication, contemporaryData),
      mp3s: getMp3sCellContent(publication),
      pieceId: publication.p,
      publicationId: publication.id,
      scoreNotes: getScoreNotesCellContent(publication),
      series: getSeriesCellContent(publication, contemporaryData),
      sheetMusic: getSheetMusicCellContent(publication),
      title: getTitleCellContent(publication, contemporaryData),
      video: getVideoCellContent(publication, contemporaryData),
      year: getYearCellContent(publication),
    };
  });
}

function preparePublicationData(
  contemporaryData: IContemporaryRow[],
): IContemporaryPublicationRow[] {
  if (!contemporaryData.length) {
    return [];
  }

  return contemporaryData.map((contemporaryRow: IContemporaryRow) => {
    const {
      arranger,
      composer,
      issue,
      lyricist,
      series,
      pieceId,
      publicationId,
      title,
    } = contemporaryRow;

    return new ContemporaryPublicationRow({
      arranger,
      composer,
      issue,
      lyricist,
      series,
      pieceId,
      publicationId,
      title,
    });
  });
}

function getVideoCellContent(
  publication: IMinContemporaryPublication,
  contemporaryData: IMinContemporaryData,
): boolean {
  const pieceId = publication.p;
  const piece = contemporaryData.p[pieceId];

  return !!piece.v;
}

function getSheetMusicCellContent(
  publication: IMinContemporaryPublication,
): boolean {
  return !!publication.sh;
}

function getMp3sCellContent(publication: IMinContemporaryPublication): boolean {
  return !!publication.m;
}

function getScoreNotesCellContent(
  publication: IMinContemporaryPublication,
): boolean {
  return !!publication.no;
}

function getSeriesCellContent(
  publication: IMinContemporaryPublication,
  contemporaryData: IMinContemporaryData,
): string {
  const seriesId = publication.s;
  const series = contemporaryData.s[seriesId];

  return series.n;
}

function getIssueCellContent(publication: IMinContemporaryPublication): string {
  let rval = publication.i;
  rval += publication.y ? ` (${publication.y})` : '';

  return rval;
}

function getTitleCellContent(
  publication: IMinContemporaryPublication,
  contemporaryData: IMinContemporaryData,
): string {
  const pieceId = publication.p;
  const piece = contemporaryData.p[pieceId];

  return piece.tt;
}

function getLyricistCellContent(
  publication: IMinContemporaryPublication,
  contemporaryData: IMinContemporaryData,
) {
  const pieceId = publication.p;
  const piece = contemporaryData.p[pieceId];

  if (!piece.l) {
    return '';
  }

  const lyricistId = piece.l;
  const lyricist = contemporaryData.c[lyricistId];

  return lyricist.n;
}

function getComposerCellContent(
  publication: IMinContemporaryPublication,
  contemporaryData: IMinContemporaryData,
) {
  const pieceId = publication.p;
  const piece = contemporaryData.p[pieceId];

  if (!piece.c) {
    return '';
  }

  const composerId = piece.c;
  const composer = contemporaryData.c[composerId];

  return composer.n;
}

function getArrangerCellContent(
  publication: IMinContemporaryPublication,
  contemporaryData: IMinContemporaryData,
) {
  const pieceId = publication.p;
  const piece = contemporaryData.p[pieceId];

  if (!piece.a) {
    return '';
  }

  const arrangerId = piece.a;
  const arranger = contemporaryData.c[arrangerId];

  return arranger.n;
}

function getYearCellContent(publication: IMinContemporaryPublication): string {
  return `${publication.y}`;
}

function getNewContemporaryFilters(
  state: IContemporaryState,
  action: IAction,
): IContemporaryFilters {
  const rval = state.contemporaryFilters;
  rval[action.key] = action.value;

  return rval;
}

function getResetContemporaryFilters(): IContemporaryFilters {
  return {
    issue: '',
    lyricist: '',
    media: false,
    series: '',
    sheetMusic: false,
    title: '',
    year: '',
  };
}

function filterData(
  contemporaryFilters: IContemporaryFilters,
  contemporaryGridData: IContemporaryRow[],
): IContemporaryRow[] {
  return contemporaryGridData.filter((row: IContemporaryRow) => {
    return Object.entries(row).every(([key, value]) => {
      const contemporaryFilter = contemporaryFilters[key];

      switch (key) {
        case 'audio':
        case 'video':
          return contemporaryFilters.media ? !!(row.mp3s || row.video) : true;

        case 'series':
          return contemporaryFilter !== ''
            ? contemporaryFilter === value
            : true;

        case 'issue':
        case 'title':
          return typeof contemporaryFilter === 'string' &&
            typeof value === 'string'
            ? textCompare(value, contemporaryFilter)
            : true;

        case 'lyricist':
          return typeof contemporaryFilter === 'string' &&
            typeof value === 'string'
            ? textCompare(value, contemporaryFilter) ||
                textCompare(row.composer, contemporaryFilter) ||
                textCompare(row.arranger, contemporaryFilter)
            : true;

        case 'year':
          if (
            typeof contemporaryFilter !== 'string' ||
            typeof value !== 'string'
          ) {
            return true;
          }

          // Handle range requests
          if (contemporaryFilter[0] === '>' || contemporaryFilter[0] === '<') {
            if (value === '') {
              return false;
            }

            const contemporaryFilterNum =
              contemporaryFilter[1] === '='
                ? parseInt(contemporaryFilter.substring(2), 10)
                : parseInt(contemporaryFilter.substring(1), 10);
            const yearNum = parseInt(value, 10);
            if (isNaN(contemporaryFilterNum) || isNaN(yearNum)) {
              return true;
            }

            if (contemporaryFilter.substring(0, 2) === '>=') {
              return yearNum >= contemporaryFilterNum;
            } else if (contemporaryFilter.substring(0, 2) === '<=') {
              return yearNum <= contemporaryFilterNum;
            } else if (contemporaryFilter[0] === '>') {
              return yearNum > contemporaryFilterNum;
            } else return yearNum < contemporaryFilterNum;
          }

          return textCompare(value, contemporaryFilter);

        case 'sheetMusic':
          return contemporaryFilters.sheetMusic ? !!row.sheetMusic : true;

        default:
          return true;
      }
    });
  });
}

function sortData(
  contemporarySort: ContemporarySort,
  contemporaryGridData: IContemporaryRow[],
): IContemporaryRow[] {
  if (contemporarySort === 'default') {
    return contemporaryGridData;
  }

  const matches = contemporarySort.match(/^(.+)(Asc|Desc)$/);
  if (!matches) {
    return contemporaryGridData;
  }

  const field = matches[1];
  const sortOrder = matches[2].toLowerCase();

  return [...contemporaryGridData].sort(
    (a: IContemporaryRow, b: IContemporaryRow) => {
      let valA: any = a[field];
      let valB: any = b[field];

      // Ignore accents when sorting
      if (typeof valA === 'string') {
        valA = removeAccents(valA);
      }
      if (typeof valB === 'string') {
        valB = removeAccents(valB);
      }

      const result = valA > valB ? 1 : valA < valB ? -1 : 0;
      return sortOrder === 'asc' ? result : result * -1;
    },
  );
}

function formatSeriesData(
  seriesData: IMinContemporarySeriesData,
): IContemporarySeriesDropdown[] {
  if (seriesData === undefined) {
    return [];
  }

  const dropdownData = Object.entries(seriesData.p).map(([key, publisher]) => {
    const series = Object.values(seriesData.s).filter((serie) => {
      return serie.p === parseInt(key, 10);
    });
    const sortedSeries = [...series].sort(sortBy('n'));

    return {
      name: publisher.n,
      series: sortedSeries.map((serie) => serie.n),
    };
  });

  return [...dropdownData].sort(sortBy('name'));
}
