import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {
  ContemporarySort,
  IContemporaryMp3Album,
  IMinContemporaryData,
  IMinContemporaryDetail,
  IMinContemporarySeriesData,
} from '../../definitions/contemporary';
import {IMinSeriesDetail} from '../../definitions/shared';
import {IAction, IStore} from '../store';

export const FETCH_CONTEMPORARY_GRID_DATA_STARTED =
  'contemporary/fetchGridStarted';
function fetchContemporaryGridDataStarted(): IAction {
  return {
    type: FETCH_CONTEMPORARY_GRID_DATA_STARTED,
  };
}
export const FETCH_CONTEMPORARY_GRID_DATA_RECEIVE =
  'contemporary/fetchGridReceive';
function fetchContemporaryGridDataReceive(data: IMinContemporaryData): IAction {
  return {
    data,
    type: FETCH_CONTEMPORARY_GRID_DATA_RECEIVE,
  };
}
export const FETCH_CONTEMPORARY_GRID_DATA_ERROR = 'contemporary/fetchGridError';
function fetchContemporaryGridDataError(): IAction {
  return {
    type: FETCH_CONTEMPORARY_GRID_DATA_ERROR,
  };
}
export function fetchContemporaryGridData(): ThunkAction<
  PromiseLike<any>,
  IStore,
  null,
  IAction
> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchContemporaryGridDataStarted());

    return fetch(`/api/contemporary`, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok) {
          dispatch(fetchContemporaryGridDataError());
          console.error(`Error fetching contemporary data: ${resp.status}`);
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchContemporaryGridDataReceive(data));
        });
      })
      .catch((err) => {
        dispatch(fetchContemporaryGridDataError());
        dispatch(fetchContemporaryPublicationDataError());
        console.error(`Error parsing contemporary data: ${err.stack}`);
      });
  };
}

export const FETCH_CONTEMPORARY_SERIES_STARTED =
  'contemporary/fetchSeriesStarted';
function fetchContemporarySeriesStarted(): IAction {
  return {
    type: FETCH_CONTEMPORARY_SERIES_STARTED,
  };
}
export const FETCH_CONTEMPORARY_SERIES_RECEIVE =
  'contemporary/fetchSeriesReceive';
function fetchContemporarySeriesReceive(
  data: IMinContemporarySeriesData,
): IAction {
  return {
    data,
    type: FETCH_CONTEMPORARY_SERIES_RECEIVE,
  };
}
export const FETCH_CONTEMPORARY_SERIES_ERROR = 'contemporary/fetchSeriesError';
function fetchContemporarySeriesError(): IAction {
  return {
    type: FETCH_CONTEMPORARY_SERIES_ERROR,
  };
}
export function fetchContemporarySeries(): ThunkAction<
  PromiseLike<any>,
  IStore,
  null,
  IAction
> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchContemporarySeriesStarted());

    return fetch(`/api/contemporary/series`, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok) {
          dispatch(fetchContemporarySeriesError());
          console.error(
            `Error fetching contemporary series data: ${resp.status}`,
          );
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchContemporarySeriesReceive(data));
        });
      })
      .catch((err) => {
        dispatch(fetchContemporarySeriesError());
        console.error(`Error parsing contemporary series data: ${err.stack}`);
      });
  };
}

export const FETCH_CONTEMPORARY_DETAIL_STARTED =
  'contemporary/fetchDetailStarted';
function fetchContemporaryDetailStarted(): IAction {
  return {
    type: FETCH_CONTEMPORARY_DETAIL_STARTED,
  };
}
export const FETCH_CONTEMPORARY_DETAIL_RECEIVE =
  'contemporary/fetchDetailReceive';
function fetchContemporaryDetailReceive(data: IMinContemporaryDetail): IAction {
  return {
    data,
    type: FETCH_CONTEMPORARY_DETAIL_RECEIVE,
  };
}
export const FETCH_CONTEMPORARY_DETAIL_ERROR = 'contemporary/fetchDetailError';
function fetchContemporaryDetailError(): IAction {
  return {
    type: FETCH_CONTEMPORARY_DETAIL_ERROR,
  };
}
export function fetchContemporaryDetail(
  publicationId: string,
  onDone: () => void,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchContemporaryDetailStarted());

    return fetch(`/api/contemporary/detail/${publicationId}`, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok) {
          dispatch(fetchContemporaryDetailError());
          console.error(`Error fetching contemporary detail: ${resp.status}`);
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchContemporaryDetailReceive(data));
          onDone();
        });
      })
      .catch((err) => {
        dispatch(fetchContemporaryDetailError());
        console.error(`Error parsing contemporary detail: ${err.stack}`);
      });
  };
}

export const FETCH_CONTEMPORARY_MP3_ALBUM_STARTED =
  'contemporary/fetchMp3AlbumStarted';
function fetchContemporaryMp3AlbumStarted(): IAction {
  return {
    type: FETCH_CONTEMPORARY_MP3_ALBUM_STARTED,
  };
}
export const FETCH_CONTEMPORARY_MP3_ALBUM_RECEIVE =
  'contemporary/fetchMp3AlbumReceive';
function fetchContemporaryMp3AlbumReceive(
  data: IContemporaryMp3Album,
): IAction {
  return {
    data,
    type: FETCH_CONTEMPORARY_MP3_ALBUM_RECEIVE,
  };
}
export const FETCH_CONTEMPORARY_MP3_ALBUM_ERROR =
  'contemporary/fetchContemporaryMp3AlbumError';
function fetchContemporaryMp3AlbumError(): IAction {
  return {
    type: FETCH_CONTEMPORARY_MP3_ALBUM_ERROR,
  };
}
export function fetchContemporaryMp3Album(
  mp3AlbumId: number,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchContemporaryMp3AlbumStarted());

    return fetch(`/api/contemporary/mp3-album/${mp3AlbumId}`, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok) {
          dispatch(fetchContemporaryMp3AlbumError());
          console.error(
            `Error fetching contemporary mp3 album: ${resp.status}`,
          );
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchContemporaryMp3AlbumReceive(data));
        });
      })
      .catch((err) => {
        dispatch(fetchContemporaryMp3AlbumError());
        console.error(`Error parsing contemporary mp3 album: ${err.stack}`);
      });
  };
}

export const FETCH_CONTEMPORARY_PUBLICATION_DATA_STARTED =
  'contemporary/fetchPublicationsStarted';
function fetchContemporaryPublicationDataStarted(): IAction {
  return {
    type: FETCH_CONTEMPORARY_PUBLICATION_DATA_STARTED,
  };
}
export const FETCH_CONTEMPORARY_PUBLICATION_DATA_RECEIVE =
  'contemporary/fetchPublicationsReceive';
function fetchContemporaryPublicationDataReceive(): IAction {
  return {
    type: FETCH_CONTEMPORARY_PUBLICATION_DATA_RECEIVE,
  };
}
export const FETCH_CONTEMPORARY_PUBLICATION_DATA_ERROR =
  'contemporary/fetchPublicationsError';
function fetchContemporaryPublicationDataError(): IAction {
  return {
    type: FETCH_CONTEMPORARY_PUBLICATION_DATA_ERROR,
  };
}
export function fetchContemporaryPublicationData(): ThunkAction<
  PromiseLike<any>,
  IStore,
  null,
  IAction
> {
  return (
    dispatch: ThunkDispatch<IStore, null, IAction>,
    getState: () => IStore,
  ) => {
    dispatch(fetchContemporaryPublicationDataStarted());

    if (getState().contemporary.contemporaryGridData.detail.length) {
      // Grid data is already fetched, don't bother re-fetching
      dispatch(fetchContemporaryPublicationDataReceive());
      return Promise.resolve();
    }

    return dispatch(fetchContemporaryGridData()).then(() => {
      dispatch(fetchContemporaryPublicationDataReceive());
    });
  };
}

export const RESET_CONTEMPORARY_DETAIL = 'contemporary/detail/reset';
export function resetContemporaryDetail(): IAction {
  return {
    type: RESET_CONTEMPORARY_DETAIL,
  };
}

export const RESET_CONTEMPORARY_MP3_ALBUM = 'contemporary/mp3Album/reset';
export function resetContemporaryMp3Album(): IAction {
  return {
    type: RESET_CONTEMPORARY_MP3_ALBUM,
  };
}

export const FILTER_CONTEMPORARY = 'contemporary/filter';
export function filterContemporary(
  key: string,
  value: boolean | string,
): IAction {
  return {
    key,
    type: FILTER_CONTEMPORARY,
    value,
  };
}

export const FILTER_CONTEMPORARY_PRIME = 'contemporary/filter/prime';
export function primeContemporaryFilter(
  key: string,
  value: boolean | string,
): IAction {
  return {
    key,
    type: FILTER_CONTEMPORARY_PRIME,
    value,
  };
}

export const FILTER_CONTEMPORARY_EXECUTE = 'contemporary/filter/execute';
export function executeContemporaryFilters(): IAction {
  return {
    type: FILTER_CONTEMPORARY_EXECUTE,
  };
}

export const FILTER_CONTEMPORARY_RESET = 'contemporary/filter/reset';
export function resetContemporaryFilters(): IAction {
  return {
    type: FILTER_CONTEMPORARY_RESET,
  };
}

export const SORT_CONTEMPORARY = 'contemporary/sort';
export function sortContemporary(contemporarySort: ContemporarySort): IAction {
  return {
    type: SORT_CONTEMPORARY,
    contemporarySort,
  };
}

export const SORT_CONTEMPORARY_PRIME = 'contemporary/sort/prime';
export function primeContemporarySort(
  contemporarySort: ContemporarySort,
): IAction {
  return {
    type: SORT_CONTEMPORARY_PRIME,
    contemporarySort,
  };
}

export const SORT_CONTEMPORARY_EXECUTE = 'contemporary/sort/execute';
export function executeContemporarySort(): IAction {
  return {
    type: SORT_CONTEMPORARY_EXECUTE,
  };
}

export const UPDATE_CONTEMPORARY_DETAIL_INDEX =
  '/contemporary/updateDetailIndex';
export function updateContemporaryDetailIndex(index: number): IAction {
  return {
    index,
    type: UPDATE_CONTEMPORARY_DETAIL_INDEX,
  };
}

export const FETCH_CONTEMPORARY_SERIES_DETAIL_STARTED =
  'contemporary/fetchSeriesDetailStarted';
function fetchContemporarySeriesDetailStarted(): IAction {
  return {
    type: FETCH_CONTEMPORARY_SERIES_DETAIL_STARTED,
  };
}
export const FETCH_CONTEMPORARY_SERIES_DETAIL_RECEIVE =
  'contemporary/fetchSeriesDetailReceive';
function fetchContemporarySeriesDetailReceive(data: IMinSeriesDetail): IAction {
  return {
    data,
    type: FETCH_CONTEMPORARY_SERIES_DETAIL_RECEIVE,
  };
}
export const FETCH_CONTEMPORARY_SERIES_DETAIL_ERROR =
  'contemporary/fetchSeriesDetailError';
function fetchContemporarySeriesDetailError(): IAction {
  return {
    type: FETCH_CONTEMPORARY_SERIES_DETAIL_ERROR,
  };
}
export function fetchContemporarySeriesDetail(
  seriesId: string,
  onDone: () => void,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchContemporarySeriesDetailStarted());

    return fetch(`/api/contemporary/series-detail/${seriesId}`, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok) {
          dispatch(fetchContemporarySeriesDetailError());
          console.error(
            `Error fetching contemporary series detail: ${resp.status}`,
          );
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchContemporarySeriesDetailReceive(data));
          onDone();
        });
      })
      .catch((err) => {
        dispatch(fetchContemporarySeriesDetailError());
        console.error(`Error parsing contemporary series detail: ${err.stack}`);
      });
  };
}

export const RESET_CONTEMPORARY_SERIES_DETAIL =
  'contemporary/seriesDetail/reset';
export function resetContemporarySeriesDetail(): IAction {
  return {
    type: RESET_CONTEMPORARY_SERIES_DETAIL,
  };
}
