import {IPublicationHallOfFamePlace} from './hallOfFame';
import {IBasicMp3Album, IMinSeriesDetail} from './shared';

export interface IBasicComposer {
  fname: string | null;
  id: number;
  lname: string;
}

export interface IComposer extends IBasicComposer {
  name: string;
}

export interface IBasicBrassType {
  id: number;
  name: string;
}

export interface IBasicBrassMedia {
  id: number;
  youtube_id: string | null;
  vimeo_id: string | null;
  video_dl_url: string | null;
}

export interface IBasicPublisher {
  id: number;
  name: string;
  short_name: string;
}

export interface IBasicBrassScoreNotes {
  author: string | null;
  content: string | null;
  id: number;
  programme_note: string | null;
}

export interface IBasicBrassPiece {
  arranger_id: number | null;
  composer_id: number;
  id: number;
  title: string;
}

export interface IBrassPiece extends IBasicBrassPiece {
  arranger?: IComposer;
  composer: IComposer;
  brassMedia: IBasicBrassMedia;
  brassTunes: IBasicBrassPublication[];
  brassTypes: IBasicBrassType[];
  hasCopyright: boolean;
}

export interface IBasicBrassPublication {
  brass_piece_id: number;
  id: number;
  brass_series_id: number;
  number: string | null;
  publication_date: string | null;
}

export interface IBrassPublication extends IBasicBrassPublication {
  brassPiece: IBrassPiece;
  brassSeries: IMinSeriesDetail;
  brassScoreNotes: IBasicBrassScoreNotes;
}

export interface IBrassPublicationRow {
  pieceId: number;
  publicationId: number;
  series: string;
  getDisplayValue(): string;
}

export class BrassPublicationRow implements IBrassPublicationRow {
  public arranger: string;
  public composer: string;
  public number: string | null;
  public pieceId: number;
  public publicationId: number;
  public series: string;
  public title: string;
  public constructor(options: {
    arranger: string;
    composer: string;
    number: string | null;
    pieceId: number;
    publicationId: number;
    series: string;
    title: string;
  }) {
    this.arranger = options.arranger;
    this.composer = options.composer;
    this.number = options.number;
    this.pieceId = options.pieceId;
    this.publicationId = options.publicationId;
    this.series = options.series;
    this.title = options.title;
  }
  public getDisplayValue() {
    let rval = `${this.series} ${this.number || ''} - ${this.title} by ${
      this.composer
    }`;
    if (this.arranger) {
      rval += ` arr. ${this.arranger}`;
    }
    return rval;
  }
}

export interface IBasicBrassSheetMusicType {
  id: number;
  name: string;
  isScoreAndParts: boolean;
  isAllParts: boolean;
  isScore: boolean;
  isSoloPart: boolean;
  isPart: boolean;
}

export interface ISimplePublicationData {
  number: string;
  title: string;
}

export interface IBasicBrassSheetMusic {
  brass_publication_id: number;
  brass_sheet_music_type_id: number;
  display_order: number;
  hard_copy: number;
  high_quality: number;
  id: number;
  num_band_set_parts: number;
  part: string;
  path: string | null; // TODO is this property even needed
}

export interface IBrassSheetMusic extends IBasicBrassSheetMusic {
  brassPublication: IBrassPublication;
  brassSheetMusicType: IBasicBrassSheetMusicType;
  hardCopyPrice: number | null;
  price: number;
}

export interface IBrassHardCopySheetMusic {
  sheetMusic: IBrassSheetMusic;
  numCopies: number;
}

export interface IBasicBrassMp3 {
  artist: string;
  brass_piece_id: number;
  id: number;
  path: string; // TODO not needed? try to remove from frontend payload
  price: number;
}

export interface IBrassMp3 extends IBasicBrassMp3 {
  albums: IBasicMp3Album[];
  brassPiece: IBrassPiece;
}

export interface IBrassMp3Album extends IBasicMp3Album {
  mp3Tracks: Array<{
    trackNumber: number;
    brassMp3: IBrassMp3;
  }>;
}

// Min definitions for grid data

export interface IMinBrassComposer {
  n: string; // name
}

export interface IMinBrassComposers {
  [id: string]: IMinBrassComposer;
}

export interface IMinBrassType {
  n: string; // name
}

export interface IMinBrassTypes {
  [id: string]: IMinBrassType;
}

export interface IMinBrassPiece {
  a: number; // arrangerId
  c: number; // composerId
  t: number[]; // typeIds
  tt: string; // title
  v: number; // video (0 or 1)
}

export interface IMinBrassPieces {
  [id: string]: IMinBrassPiece;
}

export interface IMinPublisher {
  n: string;
}

export interface IMinPublishers {
  [id: string]: IMinPublisher;
}

export interface IMinBrassSerie {
  n: string; // name
  p?: number; // publisherId
}

export interface IMinBrassSeries {
  [id: string]: IMinBrassSerie;
}

export interface IMinBrassPublication {
  id: number; // id
  m: number; // mp3s
  n: string; // number
  no: number; // score notes
  p: number; // pieceId
  s: number; // seriesId
  sh: number; // sheet music
  y: number; // year
}

export interface IMinBrassData {
  c: IMinBrassComposers;
  dt: string; // yyyy-mm-dd hh:mm:ss
  p: IMinBrassPieces;
  pb: IMinBrassPublication[];
  s: IMinBrassSeries;
  t: IMinBrassTypes;
}

export interface IMinBrassSeriesData {
  s: IMinBrassSeries;
  p: IMinPublishers;
}

// Specific grid interfaces

export interface IBrassSeriesDropdown {
  name: string;
  series: string[];
}

export interface IBrassRow {
  [key: string]: string | boolean | number;
  arranger: string;
  composer: string;
  mp3s: boolean;
  number: string;
  pieceId: number;
  publicationId: number;
  scoreNotes: boolean;
  series: string;
  sheetMusic: boolean;
  title: string;
  type: string;
  video: boolean;
  year: string;
}

export interface IBrassFilters {
  [key: string]: boolean | string;
  media: boolean;
  series: string;
  number: string;
  sheetMusic: boolean;
  type: string;
  title: string;
  composer: string;
  year: string;
}

export type BrassSort =
  | 'composerAsc'
  | 'composerDesc'
  | 'default'
  | 'numberAsc'
  | 'numberDesc'
  | 'seriesAsc'
  | 'seriesDesc'
  | 'titleAsc'
  | 'titleDesc'
  | 'typeAsc'
  | 'typeDesc'
  | 'yearAsc'
  | 'yearDesc';

export interface IBrassDetailTune {
  id: number; // piece id
  l: string; // link
  n: string; // number
  s: string; // series
  t: string; // title
}

export interface IMinBrassDetail {
  id: string | null;
  /**
   * Arranger
   */
  a: string | null;
  /**
   * Arranger ID
   */
  aid: number | null;
  /**
   * Analysis Youtube Id
   */
  an: string | null;
  /**
   * Analysis By
   */
  anb: string | null;
  /**
   * Composer
   */
  c: string | null;
  /**
   * Composer ID
   */
  cid: number | null;
  /**
   * Copyright
   */
  cr: boolean | null;
  /**
   * Digital Only
   */
  do: boolean | null;
  /**
   * Publication Date
   */
  dt: string | null;
  /**
   * Hall of Fame
   */
  hof: IPublicationHallOfFamePlace[] | null;
  /**
   * MP3s
   */
  mp3: IBrassMp3[];
  /**
   * Number
   */
  n: string | null;
  /**
   * Score Notes Author
   */
  noa: string | null;
  /**
   * Score Notes Content
   */
  noc: string | null;
  /**
   * Programme Note
   */
  nop: string | null;
  /**
   * Piece ID
   */
  pid: string | null;
  /**
   * Media is from Regal Zonophone
   */
  rz: boolean;
  /**
   * Series
   */
  s: string | null;
  /**
   * Sheet Music
   */
  sh: IBrassSheetMusic[];
  /**
   * Series ID
   */
  sid: string | null;
  /**
   * Tune Publications
   */
  tp: IBrassDetailTune[] | null;
  /**
   * Title
   */
  tt: string | null;
  /**
   * Tunes
   */
  tu: IBrassDetailTune[] | null;
  /**
   *  Types
   */
  ty: string[] | null;
  /**
   * Video Download URL
   */
  vd: string | null;
  /**
   * Vimeo Video
   */
  vm: string | null;
  /**
   * Years Before Sale
   */
  ybs: number | null;
  /**
   * Youtube Video
   */
  yt: string | null;
}
