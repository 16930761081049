import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {
  Frequency,
  IMinComposersData,
  ISalesData,
  ISalesOverview,
  IStatsData,
  IChartsData,
  IJournalsData,
  IVouchersData,
} from '../../definitions/admin';
import {IAction, IStore} from '../store';

export interface FetchDateRangeOptions {
  from: string;
  to: string;
}

export const FETCH_SALES_DATA_STARTED = 'admin/fetchSalesDataStarted';
function fetchSalesDataStarted(): IAction {
  return {
    type: FETCH_SALES_DATA_STARTED,
  };
}
export const FETCH_SALES_DATA_UNAUTHORISED = 'admin/fetchSalesDataUnauthorised';
function fetchSalesDataUnauthorised(): IAction {
  return {
    type: FETCH_SALES_DATA_UNAUTHORISED,
  };
}
export const FETCH_SALES_DATA_RECEIVE = 'admin/fetchSalesDataReceive';
function fetchSalesDataReceive(data: ISalesData): IAction {
  return {
    data,
    type: FETCH_SALES_DATA_RECEIVE,
  };
}
export const FETCH_SALES_DATA_ERROR = 'admin/fetchSalesDataError';
function fetchSalesDataError(): IAction {
  return {
    type: FETCH_SALES_DATA_ERROR,
  };
}
export const SALES_DATA_RESET = 'admin/salesDataReset';
function salesDataReset(): IAction {
  return {
    type: SALES_DATA_RESET,
  };
}

export interface FetchSalesDataOptions extends FetchDateRangeOptions {
  includeSubscriptions: boolean;
  publisherId?: number;
}

export function fetchSalesData(
  options: FetchSalesDataOptions,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    const {from, to, includeSubscriptions, publisherId} = options;

    dispatch(fetchSalesDataStarted());

    let url = `/api/admin/sales/${from}/${to}`;
    const urlParams = [];

    if (includeSubscriptions) {
      urlParams.push('include-subscriptions');
    }
    if (publisherId) {
      urlParams.push(`publisher-id=${publisherId}`);
    }

    if (urlParams.length) {
      url += `?${urlParams.join('&')}`;
    }

    return fetch(url, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok && resp.status !== 401) {
          dispatch(fetchSalesDataError());
          console.error(`Error fetching sales data: ${resp.status}`);
          return;
        }
        if (resp.status === 401) {
          dispatch(fetchSalesDataUnauthorised());
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchSalesDataReceive(data));
        });
      })
      .catch((err) => {
        dispatch(fetchSalesDataError());
        console.error(`Error parsing sales data: ${err.stack}`);
      });
  };
}

export function resetSalesData(): ThunkAction<void, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(salesDataReset());
  };
}

export const FETCH_SALES_OVERVIEW_STARTED = 'admin/fetchSalesOverviewStarted';
function fetchSalesOverviewStarted(): IAction {
  return {
    type: FETCH_SALES_OVERVIEW_STARTED,
  };
}
export const FETCH_SALES_OVERVIEW_UNAUTHORISED =
  'admin/fetchSalesOverviewUnauthorised';
function fetchSalesOverviewUnauthorised(): IAction {
  return {
    type: FETCH_SALES_OVERVIEW_UNAUTHORISED,
  };
}
export const FETCH_SALES_OVERVIEW_RECEIVE = 'admin/fetchSalesOverviewReceive';
function fetchSalesOverviewReceive(data: ISalesOverview): IAction {
  return {
    data,
    type: FETCH_SALES_OVERVIEW_RECEIVE,
  };
}
export const FETCH_SALES_OVERVIEW_ERROR = 'admin/fetchSalesOverviewError';
function fetchSalesOverviewError(): IAction {
  return {
    type: FETCH_SALES_OVERVIEW_ERROR,
  };
}

export function fetchSalesOverview(
  options: FetchSalesDataOptions,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    const {from, to, includeSubscriptions, publisherId} = options;

    dispatch(fetchSalesOverviewStarted());

    let url = `/api/admin/sales-overview/${from}/${to}`;
    const urlParams = [];

    if (includeSubscriptions) {
      urlParams.push('include-subscriptions');
    }
    if (publisherId) {
      urlParams.push(`publisher-id=${publisherId}`);
    }

    if (urlParams.length) {
      url += `?${urlParams.join('&')}`;
    }

    return fetch(url, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok && resp.status !== 401) {
          dispatch(fetchSalesOverviewError());
          console.error(`Error fetching sales data: ${resp.status}`);
          return;
        }
        if (resp.status === 401) {
          dispatch(fetchSalesOverviewUnauthorised());
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchSalesOverviewReceive(data));
        });
      })
      .catch((err) => {
        dispatch(fetchSalesOverviewError());
        console.error(`Error parsing sales data: ${err.stack}`);
      });
  };
}

export const FETCH_STATS_DATA_STARTED = 'admin/fetchStatsDataStarted';
function fetchStatsDataStarted(section: keyof IStatsData): IAction {
  return {
    section,
    type: FETCH_STATS_DATA_STARTED,
  };
}
export const FETCH_STATS_DATA_UNAUTHORISED = 'admin/fetchStatsDataUnauthorised';
function fetchStatsDataUnauthorised(): IAction {
  return {
    type: FETCH_STATS_DATA_UNAUTHORISED,
  };
}
export const FETCH_STATS_DATA_RECEIVE = 'admin/fetchStatsDataReceive';
function fetchStatsDataReceive(
  section: keyof IStatsData,
  data: IStatsData,
): IAction {
  return {
    data,
    section,
    type: FETCH_STATS_DATA_RECEIVE,
  };
}
export const FETCH_STATS_DATA_ERROR = 'admin/fetchStatsDataError';
function fetchStatsDataError(): IAction {
  return {
    type: FETCH_STATS_DATA_ERROR,
  };
}

export const STATS_DATA_RESET = 'admin/statsDataReset';
function statsDataReset(): IAction {
  return {
    type: STATS_DATA_RESET,
  };
}

export interface FetchStatsDataOptions extends FetchDateRangeOptions {
  section: keyof IStatsData;
}

export function fetchStatsData(
  options: FetchStatsDataOptions,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    const {from, to, section} = options;

    dispatch(fetchStatsDataStarted(section));

    return fetch(`/api/admin/stats/${from}/${to}/${section}`, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok && resp.status !== 401) {
          dispatch(fetchStatsDataError());
          console.error(`Error fetching stats data: ${resp.status}`);
          return;
        }
        if (resp.status === 401) {
          dispatch(fetchStatsDataUnauthorised());
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchStatsDataReceive(section, data));
        });
      })
      .catch((err) => {
        dispatch(fetchStatsDataError());
        console.error(`Error parsing stats data: ${err.stack}`);
      });
  };
}

export function resetStatsData(): ThunkAction<void, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(statsDataReset());
  };
}

export const FETCH_CHARTS_DATA_STARTED = 'admin/fetchChartsDataStarted';
function fetchChartsDataStarted(): IAction {
  return {
    type: FETCH_CHARTS_DATA_STARTED,
  };
}
export const FETCH_CHARTS_DATA_UNAUTHORISED =
  'admin/fetchChartsDataUnauthorised';
function fetchChartsDataUnauthorised(): IAction {
  return {
    type: FETCH_CHARTS_DATA_UNAUTHORISED,
  };
}
export const FETCH_CHARTS_DATA_RECEIVE = 'admin/fetchChartsDataReceive';
function fetchChartsDataReceive(data: IChartsData): IAction {
  return {
    data,
    type: FETCH_CHARTS_DATA_RECEIVE,
  };
}
export const FETCH_CHARTS_DATA_ERROR = 'admin/fetchChartsDataError';
function fetchChartsDataError(): IAction {
  return {
    type: FETCH_CHARTS_DATA_ERROR,
  };
}

export const CHARTS_DATA_RESET = 'admin/chartsDataReset';
function chartsDataReset(): IAction {
  return {
    type: CHARTS_DATA_RESET,
  };
}

export interface FetchChartsDataOptions extends FetchDateRangeOptions {
  frequency: Frequency;
}

export function fetchChartsData(
  options: FetchChartsDataOptions,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    const {from, to, frequency} = options;

    dispatch(fetchChartsDataStarted());

    return fetch(`/api/admin/charts/${from}/${to}/${frequency}`, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok && resp.status !== 401) {
          dispatch(fetchChartsDataError());
          console.error(`Error fetching charts data: ${resp.status}`);
          return;
        }
        if (resp.status === 401) {
          dispatch(fetchChartsDataUnauthorised());
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchChartsDataReceive(data));
        });
      })
      .catch((err) => {
        dispatch(fetchChartsDataError());
        console.error(`Error parsing charts data: ${err.stack}`);
      });
  };
}

export function resetChartsData(): ThunkAction<void, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(chartsDataReset());
  };
}

export const FETCH_JOURNALS_DATA_STARTED = 'admin/fetchJournalsDataStarted';
function fetchJournalsDataStarted(): IAction {
  return {
    type: FETCH_JOURNALS_DATA_STARTED,
  };
}
export const FETCH_JOURNALS_DATA_UNAUTHORISED =
  'admin/fetchJournalsDataUnauthorised';
function fetchJournalsDataUnauthorised(): IAction {
  return {
    type: FETCH_JOURNALS_DATA_UNAUTHORISED,
  };
}
export const FETCH_JOURNALS_DATA_RECEIVE = 'admin/fetchJournalsDataReceive';
function fetchJournalsDataReceive(data: IJournalsData): IAction {
  return {
    data,
    type: FETCH_JOURNALS_DATA_RECEIVE,
  };
}
export const FETCH_JOURNALS_DATA_ERROR = 'admin/fetchJournalsDataError';
function fetchJournalsDataError(): IAction {
  return {
    type: FETCH_JOURNALS_DATA_ERROR,
  };
}

export function fetchJournalsData(): ThunkAction<
  PromiseLike<any>,
  IStore,
  null,
  IAction
> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchJournalsDataStarted());

    return fetch(`/api/admin/subscriptions/journals`, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok && resp.status !== 401) {
          dispatch(fetchJournalsDataError());
          console.error(`Error fetching subscriptions data: ${resp.status}`);
          return;
        }
        if (resp.status === 401) {
          dispatch(fetchJournalsDataUnauthorised());
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchJournalsDataReceive(data));
        });
      })
      .catch((err) => {
        dispatch(fetchJournalsDataError());
        console.error(`Error parsing subscriptions data: ${err.stack}`);
      });
  };
}

export const FETCH_VOUCHERS_DATA_STARTED = 'admin/fetchVouchersDataStarted';
function fetchVouchersDataStarted(): IAction {
  return {
    type: FETCH_VOUCHERS_DATA_STARTED,
  };
}
export const FETCH_VOUCHERS_DATA_UNAUTHORISED =
  'admin/fetchVouchersDataUnauthorised';
function fetchVouchersDataUnauthorised(): IAction {
  return {
    type: FETCH_VOUCHERS_DATA_UNAUTHORISED,
  };
}
export const FETCH_VOUCHERS_DATA_RECEIVE = 'admin/fetchVouchersDataReceive';
function fetchVouchersDataReceive(data: IVouchersData): IAction {
  return {
    data,
    type: FETCH_VOUCHERS_DATA_RECEIVE,
  };
}
export const FETCH_VOUCHERS_DATA_ERROR = 'admin/fetchVouchersDataError';
function fetchVouchersDataError(): IAction {
  return {
    type: FETCH_VOUCHERS_DATA_ERROR,
  };
}

export function fetchVouchersData(): ThunkAction<
  PromiseLike<any>,
  IStore,
  null,
  IAction
> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchVouchersDataStarted());

    return fetch(`/api/admin/subscriptions/existingVouchers`, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok && resp.status !== 401) {
          dispatch(fetchVouchersDataError());
          console.error(`Error fetching subscriptions data: ${resp.status}`);
          return;
        }
        if (resp.status === 401) {
          dispatch(fetchVouchersDataUnauthorised());
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchVouchersDataReceive(data));
        });
      })
      .catch((err) => {
        dispatch(fetchVouchersDataError());
        console.error(`Error parsing subscriptions data: ${err.stack}`);
      });
  };
}

export const SUBSCRIPTIONS_GENERATE_VOUCHER_STARTED =
  'admin/subscriptionsGenerateVoucherStarted';
function subscriptionsGenerateVoucherStarted(): IAction {
  return {
    type: SUBSCRIPTIONS_GENERATE_VOUCHER_STARTED,
  };
}

export const SUBSCRIPTIONS_GENERATE_VOUCHER_ERROR =
  'admin/subscriptionsGenerateVoucherError';
function subscriptionsGenerateVoucherError(): IAction {
  return {
    type: SUBSCRIPTIONS_GENERATE_VOUCHER_ERROR,
  };
}

export const SUBSCRIPTIONS_GENERATE_VOUCHER_RECEIVE =
  'admin/subscriptionsGenerateVoucherReceive';
function subscriptionsGenerateVoucherReceive(data: {code: string}): IAction {
  return {
    data,
    type: SUBSCRIPTIONS_GENERATE_VOUCHER_RECEIVE,
  };
}

export function generateSubscriptionVoucher(
  customerName: string,
  brassJournalIds: number[],
  sequenceNumber: string,
  email: string,
) {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(subscriptionsGenerateVoucherStarted());

    return fetch(`/api/admin/subscriptions/generateVoucher`, {
      body: JSON.stringify({
        customerName,
        brassJournals: brassJournalIds,
        email,
        sequenceNumber,
      }),
      credentials: 'same-origin',
      method: 'POST',
    })
      .then((resp: Response) => {
        if (!resp.ok) {
          dispatch(subscriptionsGenerateVoucherError());
          console.error(
            `Error generating subscription voucher: ${resp.status}`,
          );
          return;
        }

        return resp.json().then((data) => {
          dispatch(subscriptionsGenerateVoucherReceive(data.code));
          dispatch(fetchVouchersData());
        });
      })
      .catch((err) => {
        dispatch(subscriptionsGenerateVoucherError());
        console.error(`Error generating subscription voucher: ${err.stack}`);
      });
  };
}

export const REFRESH_CACHE_STARTED = 'admin/cacheRefreshStarted';
function refreshStarted(): IAction {
  return {
    type: REFRESH_CACHE_STARTED,
  };
}
export const REFRESH_CACHE_UNAUTHORISED = 'admin/cacheRefreshUnauthorised';
function refreshUnauthorised(): IAction {
  return {
    type: REFRESH_CACHE_UNAUTHORISED,
  };
}
export const REFRESH_CACHE_ERROR = 'admin/cacheRefreshError';
function refreshError(): IAction {
  return {
    type: REFRESH_CACHE_ERROR,
  };
}
export const REFRESH_CACHE_FINISHED = 'admin/cacheRefreshFinished';
function refreshFinished(): IAction {
  return {
    type: REFRESH_CACHE_FINISHED,
  };
}

export function refreshCache(
  onDone: () => void,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(refreshStarted());

    return fetch(`/api/admin/refresh`, {
      credentials: 'same-origin',
      method: 'POST',
    })
      .then((resp: Response) => {
        if (resp.status === 401) {
          // Unauthorised
          dispatch(refreshUnauthorised());
          return;
        }
        if (!resp.ok) {
          console.error(`Error refreshing cache: ${resp.status}`);
          dispatch(refreshError());
          return;
        }
        return resp.json().then(() => {
          dispatch(refreshFinished());
          onDone();
        });
      })
      .catch((err) => {
        dispatch(refreshError());
        console.error(`Error parsing refresh cache response: ${err.stack}`);
      });
  };
}

export const FETCH_COMPOSERS_STARTED = 'admin/fetchComposersStarted';
function fetchComposersStarted(): IAction {
  return {
    type: FETCH_COMPOSERS_STARTED,
  };
}
export const FETCH_COMPOSERS_RECEIVE = 'admin/fetchComposersReceive';
function fetchComposersReceive(data: IMinComposersData): IAction {
  return {
    data,
    type: FETCH_COMPOSERS_RECEIVE,
  };
}
export const FETCH_COMPOSERS_ERROR = 'admin/fetchComposersError';
function fetchComposersError(): IAction {
  return {
    type: FETCH_COMPOSERS_ERROR,
  };
}
export function fetchComposers(): ThunkAction<
  PromiseLike<any>,
  IStore,
  null,
  IAction
> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchComposersStarted());

    return fetch(`/api/admin/composers`, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok) {
          dispatch(fetchComposersError());
          console.error(`Error fetching composers data: ${resp.status}`);
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchComposersReceive(data));
        });
      })
      .catch((err) => {
        dispatch(fetchComposersError());
        console.error(`Error parsing composers data: ${err.stack}`);
      });
  };
}
