import uniqBy from 'lodash-es/uniqBy';
import removeAccents from 'remove-accents';
import {VocalHallOfFameRow} from '../../definitions/hallOfFame';
import {IMinSeriesDetail} from '../../definitions/shared';
import {
  IMinVocalData,
  IMinVocalDetail,
  IMinVocalPublication,
  IMinVocalSeriesData,
  IVocalFilters,
  IVocalMp3Album,
  IVocalPublicationRow,
  IVocalRow,
  IVocalSeriesDropdown,
  VocalPublicationRow,
  VocalSort,
} from '../../definitions/vocal';
import sortBy from '../../utils/sortBy';
import textCompare from '../../utils/textCompare';
import {
  FETCH_VOCAL_DETAIL_ERROR,
  FETCH_VOCAL_DETAIL_RECEIVE,
  FETCH_VOCAL_DETAIL_STARTED,
  FETCH_VOCAL_GRID_DATA_ERROR,
  FETCH_VOCAL_GRID_DATA_RECEIVE,
  FETCH_VOCAL_GRID_DATA_STARTED,
  FETCH_VOCAL_MP3_ALBUM_ERROR,
  FETCH_VOCAL_MP3_ALBUM_RECEIVE,
  FETCH_VOCAL_MP3_ALBUM_STARTED,
  FETCH_VOCAL_SERIES_ERROR,
  FETCH_VOCAL_SERIES_RECEIVE,
  FETCH_VOCAL_SERIES_STARTED,
  FETCH_VOCAL_SERIES_DETAIL_ERROR,
  FETCH_VOCAL_SERIES_DETAIL_RECEIVE,
  FETCH_VOCAL_SERIES_DETAIL_STARTED,
  FILTER_VOCAL,
  FILTER_VOCAL_EXECUTE,
  FILTER_VOCAL_PRIME,
  FILTER_VOCAL_RESET,
  RESET_VOCAL_DETAIL,
  RESET_VOCAL_MP3_ALBUM,
  RESET_VOCAL_SERIES_DETAIL,
  SORT_VOCAL,
  SORT_VOCAL_EXECUTE,
  SORT_VOCAL_PRIME,
  UPDATE_VOCAL_DETAIL_INDEX,
  FETCH_VOCAL_PUBLICATION_DATA_STARTED,
  FETCH_VOCAL_PUBLICATION_DATA_RECEIVE,
  FETCH_VOCAL_PUBLICATION_DATA_ERROR,
} from '../actions';
import {IAction} from '../store';

export interface IVocalState {
  vocalDetailData: {
    detail: IMinVocalDetail;
    error: boolean;
    fetching: boolean;
    index: number;
  };
  vocalFilters: IVocalFilters;
  vocalGridData: {
    detail: IVocalRow[];
    error: boolean;
    fetched: boolean;
    fetching: boolean;
    full: IVocalRow[];
    hallOfFame: VocalHallOfFameRow[];
  };
  vocalMp3AlbumData: {
    detail?: IVocalMp3Album;
    error: boolean;
    fetching: boolean;
  };
  vocalPublicationData: {
    detail: IVocalPublicationRow[];
    error: boolean;
    fetching: boolean;
  };
  vocalSeriesData: {
    detail: IVocalSeriesDropdown[];
    error: boolean;
    fetching: boolean;
  };
  vocalSeriesDetailData: {
    detail: IMinSeriesDetail;
    error: boolean;
    fetching: boolean;
  };
  vocalSort: VocalSort;
}

export const initialState: IVocalState = {
  vocalDetailData: {
    detail: {
      a: null,
      aid: null,
      c: null,
      cid: null,
      cr: null,
      do: null,
      dt: null,
      hof: null,
      i: null,
      id: null,
      l: null,
      lid: null,
      mp3: [],
      noa: null,
      noc: null,
      nop: null,
      pid: null,
      rz: false,
      s: null,
      sid: null,
      sh: [],
      tt: null,
      vd: null,
      vm: null,
      ybs: null,
      yt: null,
    },
    error: false,
    fetching: false,
    index: 0,
  },
  vocalFilters: {
    issue: '',
    lyricist: '',
    media: false,
    series: '',
    sheetMusic: false,
    title: '',
    year: '',
  },
  vocalGridData: {
    detail: [],
    error: false,
    fetched: false,
    fetching: false,
    full: [],
    hallOfFame: [],
  },
  vocalMp3AlbumData: {
    error: false,
    fetching: false,
  },
  vocalPublicationData: {
    detail: [],
    error: false,
    fetching: false,
  },
  vocalSeriesData: {
    detail: [],
    error: false,
    fetching: false,
  },
  vocalSeriesDetailData: {
    detail: {
      id: null,
      d: null,
      fy: null,
      ly: null,
      n: null,
      p: null,
    },
    error: false,
    fetching: false,
  },
  vocalSort: 'default',
};

export default function vocalReducer(
  state = initialState,
  action: IAction,
): IVocalState {
  switch (action.type) {
    case FETCH_VOCAL_GRID_DATA_STARTED:
      return {
        ...state,
        vocalGridData: {
          ...state.vocalGridData,
          error: false,
          fetching: true,
        },
      };

    case FETCH_VOCAL_GRID_DATA_RECEIVE: {
      const gridData = prepareData(action.data);
      return {
        ...state,
        vocalGridData: {
          detail: sortData(state.vocalSort, gridData),
          error: false,
          fetched: true,
          fetching: false,
          full: gridData,
          hallOfFame: [
            ...(action.includeHofData
              ? prepareHallOfFameData(action.data)
              : []),
          ],
        },
      };
    }

    case FETCH_VOCAL_GRID_DATA_ERROR:
      return {
        ...state,
        vocalGridData: {
          ...state.vocalGridData,
          error: true,
          fetching: false,
        },
      };

    case FETCH_VOCAL_DETAIL_STARTED:
      return {
        ...state,
        vocalDetailData: {
          ...state.vocalDetailData,
          error: false,
          fetching: true,
        },
      };

    case FETCH_VOCAL_DETAIL_RECEIVE:
      return {
        ...state,
        vocalDetailData: {
          ...state.vocalDetailData,
          detail: action.data,
          error: false,
          fetching: false,
        },
      };

    case FETCH_VOCAL_DETAIL_ERROR:
      return {
        ...state,
        vocalDetailData: {
          ...state.vocalDetailData,
          error: true,
          fetching: false,
        },
      };

    case FETCH_VOCAL_MP3_ALBUM_STARTED:
      return {
        ...state,
        vocalMp3AlbumData: {
          ...state.vocalMp3AlbumData,
          error: false,
          fetching: true,
        },
      };

    case FETCH_VOCAL_MP3_ALBUM_RECEIVE:
      return {
        ...state,
        vocalMp3AlbumData: {
          ...state.vocalMp3AlbumData,
          detail: action.data,
          error: false,
          fetching: false,
        },
      };

    case FETCH_VOCAL_MP3_ALBUM_ERROR:
      return {
        ...state,
        vocalMp3AlbumData: {
          ...state.vocalMp3AlbumData,
          error: true,
          fetching: false,
        },
      };

    case FETCH_VOCAL_SERIES_STARTED:
      return {
        ...state,
        vocalSeriesData: {
          ...state.vocalSeriesData,
          error: false,
          fetching: true,
        },
      };

    case FETCH_VOCAL_SERIES_RECEIVE: {
      const seriesData = formatSeriesData(action.data);
      return {
        ...state,
        vocalSeriesData: {
          detail: seriesData,
          error: false,
          fetching: false,
        },
      };
    }

    case FETCH_VOCAL_SERIES_ERROR:
      return {
        ...state,
        vocalSeriesData: {
          ...state.vocalSeriesData,
          error: true,
          fetching: false,
        },
      };

    case RESET_VOCAL_DETAIL:
      return {
        ...state,
        vocalDetailData: {
          ...state.vocalDetailData,
          detail: initialState.vocalDetailData.detail,
        },
      };

    case RESET_VOCAL_MP3_ALBUM:
      return {
        ...state,
        vocalMp3AlbumData: {
          ...state.vocalMp3AlbumData,
          detail: undefined,
        },
      };

    case FILTER_VOCAL: {
      const vocalFilters = getNewVocalFilters(state, action);
      return {
        ...state,
        vocalFilters,
        vocalGridData: {
          ...state.vocalGridData,
          detail: sortData(
            state.vocalSort,
            filterData(vocalFilters, state.vocalGridData.full),
          ),
        },
      };
    }

    case FILTER_VOCAL_PRIME: {
      const vocalFilters = getNewVocalFilters(state, action);
      return {
        ...state,
        vocalFilters,
      };
    }

    case FILTER_VOCAL_EXECUTE:
      return {
        ...state,
        vocalGridData: {
          ...state.vocalGridData,
          detail: sortData(
            state.vocalSort,
            filterData(state.vocalFilters, state.vocalGridData.full),
          ),
        },
      };

    case FILTER_VOCAL_RESET: {
      const resetVocalFilters = getResetVocalFilters();
      const resetSort = 'default';
      return {
        ...state,
        vocalDetailData: {
          ...state.vocalDetailData,
          index: 0,
        },
        vocalFilters: resetVocalFilters,
        vocalGridData: {
          ...state.vocalGridData,
          detail: sortData(
            resetSort,
            filterData(resetVocalFilters, state.vocalGridData.full),
          ),
        },
        vocalSort: resetSort,
      };
    }

    case SORT_VOCAL: {
      return {
        ...state,
        vocalGridData: {
          ...state.vocalGridData,
          detail: sortData(action.vocalSort, state.vocalGridData.detail),
        },
        vocalSort: action.vocalSort,
      };
    }

    case SORT_VOCAL_PRIME: {
      return {
        ...state,
        vocalSort: action.vocalSort,
      };
    }

    case SORT_VOCAL_EXECUTE:
      return {
        ...state,
        vocalGridData: {
          ...state.vocalGridData,
          detail: sortData(state.vocalSort, state.vocalGridData.detail),
        },
      };

    case UPDATE_VOCAL_DETAIL_INDEX:
      return {
        ...state,
        vocalDetailData: {
          ...state.vocalDetailData,
          index: action.index,
        },
      };

    case FETCH_VOCAL_PUBLICATION_DATA_STARTED:
      return {
        ...state,
        vocalPublicationData: {
          ...state.vocalPublicationData,
          error: false,
          fetching: true,
        },
      };

    case FETCH_VOCAL_PUBLICATION_DATA_RECEIVE: {
      const vocalPublicationData = preparePublicationData(
        state.vocalGridData.detail,
      );
      return {
        ...state,
        vocalPublicationData: {
          detail: vocalPublicationData,
          error: false,
          fetching: false,
        },
      };
    }

    case FETCH_VOCAL_PUBLICATION_DATA_ERROR:
      return {
        ...state,
        vocalPublicationData: {
          ...state.vocalPublicationData,
          error: true,
          fetching: false,
        },
      };

    case FETCH_VOCAL_SERIES_DETAIL_STARTED:
      return {
        ...state,
        vocalSeriesDetailData: {
          ...state.vocalSeriesDetailData,
          error: false,
          fetching: true,
        },
      };

    case FETCH_VOCAL_SERIES_DETAIL_RECEIVE:
      return {
        ...state,
        vocalSeriesDetailData: {
          ...state.vocalSeriesDetailData,
          detail: action.data,
          error: false,
          fetching: false,
        },
      };

    case FETCH_VOCAL_SERIES_DETAIL_ERROR:
      return {
        ...state,
        vocalSeriesDetailData: {
          ...state.vocalSeriesDetailData,
          error: true,
          fetching: false,
        },
      };

    case RESET_VOCAL_SERIES_DETAIL:
      return {
        ...state,
        vocalSeriesDetailData: {
          ...state.vocalSeriesDetailData,
          detail: initialState.vocalSeriesDetailData.detail,
        },
      };

    default:
      return state;
  }
}

function prepareData(vocalData: IMinVocalData): IVocalRow[] {
  if (vocalData === undefined) {
    return [];
  }

  return vocalData.pb.map((publication) => {
    return {
      arranger: getArrangerCellContent(publication, vocalData),
      composer: getComposerCellContent(publication, vocalData),
      issue: getIssueCellContent(publication),
      lyricist: getLyricistCellContent(publication, vocalData),
      mp3s: getMp3sCellContent(publication),
      pieceId: publication.p,
      publicationId: publication.id,
      scoreNotes: getScoreNotesCellContent(publication),
      series: getSeriesCellContent(publication, vocalData),
      sheetMusic: getSheetMusicCellContent(publication),
      title: getTitleCellContent(publication, vocalData),
      video: getVideoCellContent(publication, vocalData),
      year: getYearCellContent(publication),
    };
  });
}

function prepareHallOfFameData(vocalData: IMinVocalData): VocalHallOfFameRow[] {
  if (vocalData === undefined) {
    return [];
  }

  return uniqBy(vocalData.pb, 'p').map((publication): VocalHallOfFameRow => {
    return new VocalHallOfFameRow({
      arranger: getArrangerCellContent(publication, vocalData),
      composer: getComposerCellContent(publication, vocalData),
      lyricist: getLyricistCellContent(publication, vocalData),
      pieceId: publication.p,
      series: getSeriesCellContent(publication, vocalData),
      title: getTitleCellContent(publication, vocalData),
      year: parseInt(getYearCellContent(publication), 10),
    });
  });
}

function preparePublicationData(
  vocalData: IVocalRow[],
): IVocalPublicationRow[] {
  if (!vocalData.length) {
    return [];
  }

  return vocalData.map((vocalRow: IVocalRow) => {
    const {
      arranger,
      composer,
      issue,
      lyricist,
      series,
      pieceId,
      publicationId,
      title,
    } = vocalRow;

    return new VocalPublicationRow({
      arranger,
      composer,
      issue,
      lyricist,
      series,
      pieceId,
      publicationId,
      title,
    });
  });
}

function getVideoCellContent(
  publication: IMinVocalPublication,
  vocalData: IMinVocalData,
): boolean {
  const pieceId = publication.p;
  const piece = vocalData.p[pieceId];

  return !!piece.v;
}

function getSheetMusicCellContent(publication: IMinVocalPublication): boolean {
  return !!publication.sh;
}

function getMp3sCellContent(publication: IMinVocalPublication): boolean {
  return !!publication.m;
}

function getScoreNotesCellContent(publication: IMinVocalPublication): boolean {
  return !!publication.no;
}

function getSeriesCellContent(
  publication: IMinVocalPublication,
  vocalData: IMinVocalData,
): string {
  const seriesId = publication.s;
  const series = vocalData.s[seriesId];

  return series.n;
}

function getIssueCellContent(publication: IMinVocalPublication): string {
  let rval = publication.i;
  rval += publication.y ? ` (${publication.y})` : '';

  return rval;
}

function getTitleCellContent(
  publication: IMinVocalPublication,
  vocalData: IMinVocalData,
): string {
  const pieceId = publication.p;
  const piece = vocalData.p[pieceId];

  return piece.tt;
}

function getLyricistCellContent(
  publication: IMinVocalPublication,
  vocalData: IMinVocalData,
) {
  const pieceId = publication.p;
  const piece = vocalData.p[pieceId];

  if (!piece.l) {
    return '';
  }

  const lyricistId = piece.l;
  const lyricist = vocalData.c[lyricistId];

  return lyricist.n;
}

function getComposerCellContent(
  publication: IMinVocalPublication,
  vocalData: IMinVocalData,
) {
  const pieceId = publication.p;
  const piece = vocalData.p[pieceId];

  if (!piece.c) {
    return '';
  }

  const composerId = piece.c;
  const composer = vocalData.c[composerId];

  return composer.n;
}

function getArrangerCellContent(
  publication: IMinVocalPublication,
  vocalData: IMinVocalData,
) {
  const pieceId = publication.p;
  const piece = vocalData.p[pieceId];

  if (!piece.a) {
    return '';
  }

  const arrangerId = piece.a;
  const arranger = vocalData.c[arrangerId];

  return arranger.n;
}

function getYearCellContent(publication: IMinVocalPublication): string {
  return `${publication.y}`;
}

function getNewVocalFilters(
  state: IVocalState,
  action: IAction,
): IVocalFilters {
  const rval = state.vocalFilters;
  rval[action.key] = action.value;

  return rval;
}

function getResetVocalFilters(): IVocalFilters {
  return {
    issue: '',
    lyricist: '',
    media: false,
    series: '',
    sheetMusic: false,
    title: '',
    year: '',
  };
}

function filterData(
  vocalFilters: IVocalFilters,
  vocalGridData: IVocalRow[],
): IVocalRow[] {
  return vocalGridData.filter((row: IVocalRow) => {
    return Object.entries(row).every(([key, value]) => {
      const vocalFilter = vocalFilters[key];

      switch (key) {
        case 'audio':
        case 'video':
          return vocalFilters.media ? !!(row.mp3s || row.video) : true;

        case 'series':
          return vocalFilter !== '' ? vocalFilter === value : true;

        case 'issue':
        case 'title':
          return typeof vocalFilter === 'string' && typeof value === 'string'
            ? textCompare(value, vocalFilter)
            : true;

        case 'lyricist':
          return typeof vocalFilter === 'string' && typeof value === 'string'
            ? textCompare(value, vocalFilter) ||
                textCompare(row.composer, vocalFilter) ||
                textCompare(row.arranger, vocalFilter)
            : true;

        case 'year':
          if (typeof vocalFilter !== 'string' || typeof value !== 'string') {
            return true;
          }

          // Handle range requests
          if (vocalFilter[0] === '>' || vocalFilter[0] === '<') {
            if (value === '') {
              return false;
            }

            const vocalFilterNum =
              vocalFilter[1] === '='
                ? parseInt(vocalFilter.substring(2), 10)
                : parseInt(vocalFilter.substring(1), 10);
            const yearNum = parseInt(value, 10);
            if (isNaN(vocalFilterNum) || isNaN(yearNum)) {
              return true;
            }

            if (vocalFilter.substring(0, 2) === '>=') {
              return yearNum >= vocalFilterNum;
            } else if (vocalFilter.substring(0, 2) === '<=') {
              return yearNum <= vocalFilterNum;
            } else if (vocalFilter[0] === '>') {
              return yearNum > vocalFilterNum;
            } else return yearNum < vocalFilterNum;
          }

          return textCompare(value, vocalFilter);

        case 'sheetMusic':
          return vocalFilters.sheetMusic ? !!row.sheetMusic : true;

        default:
          return true;
      }
    });
  });
}

function sortData(
  vocalSort: VocalSort,
  vocalGridData: IVocalRow[],
): IVocalRow[] {
  if (vocalSort === 'default') {
    return vocalGridData;
  }

  const matches = vocalSort.match(/^(.+)(Asc|Desc)$/);
  if (!matches) {
    return vocalGridData;
  }

  const field = matches[1];
  const sortOrder = matches[2].toLowerCase();

  return [...vocalGridData].sort((a: IVocalRow, b: IVocalRow) => {
    let valA: any = a[field];
    let valB: any = b[field];

    // Ignore accents when sorting
    if (typeof valA === 'string') {
      valA = removeAccents(valA);
    }
    if (typeof valB === 'string') {
      valB = removeAccents(valB);
    }

    const result = valA > valB ? 1 : valA < valB ? -1 : 0;
    return sortOrder === 'asc' ? result : result * -1;
  });
}

function formatSeriesData(
  seriesData: IMinVocalSeriesData,
): IVocalSeriesDropdown[] {
  if (seriesData === undefined) {
    return [];
  }

  const dropdownData = Object.entries(seriesData.p).map(([key, publisher]) => {
    const series = Object.values(seriesData.s).filter((serie) => {
      return serie.p === parseInt(key, 10);
    });
    const sortedSeries = [...series].sort(sortBy('n'));

    return {
      name: publisher.n,
      series: sortedSeries.map((serie) => serie.n),
    };
  });

  return [...dropdownData].sort(sortBy('name'));
}
