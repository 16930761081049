import uniqBy from 'lodash-es/uniqBy';
import removeAccents from 'remove-accents';
import {
  BrassPublicationRow,
  BrassSort,
  IBrassFilters,
  IBrassMp3Album,
  IBrassPublicationRow,
  IBrassRow,
  IBrassSeriesDropdown,
  IMinBrassData,
  IMinBrassDetail,
  IMinBrassPublication,
  IMinBrassSeriesData,
  ISimplePublicationData,
} from '../../definitions/brass';
import {BrassHallOfFameRow} from '../../definitions/hallOfFame';
import {IMinSeriesDetail} from '../../definitions/shared';
import sortBy from '../../utils/sortBy';
import textCompare from '../../utils/textCompare';
import {
  FETCH_BRASS_DETAIL_ERROR,
  FETCH_BRASS_DETAIL_RECEIVE,
  FETCH_BRASS_DETAIL_STARTED,
  FETCH_BRASS_GRID_DATA_ERROR,
  FETCH_BRASS_GRID_DATA_RECEIVE,
  FETCH_BRASS_GRID_DATA_STARTED,
  FETCH_BRASS_MP3_ALBUM_ERROR,
  FETCH_BRASS_MP3_ALBUM_RECEIVE,
  FETCH_BRASS_MP3_ALBUM_STARTED,
  FETCH_BRASS_PUBLICATION_DATA_ERROR,
  FETCH_BRASS_PUBLICATION_DATA_RECEIVE,
  FETCH_BRASS_PUBLICATION_DATA_STARTED,
  FETCH_BRASS_SERIES_DETAIL_ERROR,
  FETCH_BRASS_SERIES_DETAIL_RECEIVE,
  FETCH_BRASS_SERIES_DETAIL_STARTED,
  FETCH_BRASS_SERIES_ERROR,
  FETCH_BRASS_SERIES_RECEIVE,
  FETCH_BRASS_SERIES_STARTED,
  FILTER_BRASS,
  FILTER_BRASS_EXECUTE,
  FILTER_BRASS_PRIME,
  FILTER_BRASS_RESET,
  RESET_BRASS_DETAIL,
  RESET_BRASS_MP3_ALBUM,
  RESET_BRASS_SERIES_DETAIL,
  SORT_BRASS,
  SORT_BRASS_EXECUTE,
  SORT_BRASS_PRIME,
  UPDATE_BRASS_DETAIL_INDEX,
} from '../actions';
import {IAction} from '../store';

export interface IBrassState {
  brassDetailData: {
    detail: IMinBrassDetail;
    error: boolean;
    fetching: boolean;
    index: number;
  };
  brassFilters: IBrassFilters;
  brassGridData: {
    christmasCollectionData: ISimplePublicationData[];
    detail: IBrassRow[];
    error: boolean;
    fetched: boolean;
    fetching: boolean;
    full: IBrassRow[];
    hallOfFame: BrassHallOfFameRow[];
  };
  brassMp3AlbumData: {
    detail?: IBrassMp3Album;
    error: boolean;
    fetching: boolean;
  };
  brassPublicationData: {
    detail: IBrassPublicationRow[];
    error: boolean;
    fetching: boolean;
  };
  brassSeriesData: {
    detail: IBrassSeriesDropdown[];
    error: boolean;
    fetching: boolean;
  };
  brassSeriesDetailData: {
    detail: IMinSeriesDetail;
    error: boolean;
    fetching: boolean;
  };
  brassSort: BrassSort;
}

export const initialState: IBrassState = {
  brassDetailData: {
    detail: {
      a: null,
      aid: null,
      an: null,
      anb: null,
      c: null,
      cid: null,
      cr: null,
      do: null,
      dt: null,
      hof: null,
      id: null,
      mp3: [],
      n: null,
      noa: null,
      noc: null,
      nop: null,
      pid: null,
      rz: false,
      s: null,
      sh: [],
      sid: null,
      tp: null,
      tt: null,
      tu: null,
      ty: null,
      vd: null,
      vm: null,
      ybs: null,
      yt: null,
    },
    error: false,
    fetching: false,
    index: 0,
  },
  brassFilters: {
    composer: '',
    media: false,
    number: '',
    series: '',
    sheetMusic: false,
    title: '',
    type: '',
    year: '',
  },
  brassGridData: {
    christmasCollectionData: [],
    detail: [],
    error: false,
    fetched: false,
    fetching: false,
    full: [],
    hallOfFame: [],
  },
  brassMp3AlbumData: {
    error: false,
    fetching: false,
  },
  brassPublicationData: {
    detail: [],
    error: false,
    fetching: false,
  },
  brassSeriesData: {
    detail: [],
    error: false,
    fetching: false,
  },
  brassSeriesDetailData: {
    detail: {
      id: null,
      d: null,
      fy: null,
      ly: null,
      n: null,
      p: null,
    },
    error: false,
    fetching: false,
  },
  brassSort: 'default',
};

export default function brassReducer(
  state = initialState,
  action: IAction,
): IBrassState {
  switch (action.type) {
    case FETCH_BRASS_GRID_DATA_STARTED:
      return {
        ...state,
        brassGridData: {
          ...state.brassGridData,
          error: false,
          fetching: true,
        },
      };

    case FETCH_BRASS_GRID_DATA_RECEIVE: {
      const gridData = prepareData(action.data);
      return {
        ...state,
        brassGridData: {
          christmasCollectionData: [
            ...(action.includeCccData
              ? prepareChristmasCollectionData(action.data)
              : []),
          ],
          detail: sortData(state.brassSort, gridData),
          error: false,
          fetched: true,
          fetching: false,
          full: gridData,
          hallOfFame: [
            ...(action.includeHofData
              ? prepareHallOfFameData(action.data)
              : []),
          ],
        },
      };
    }

    case FETCH_BRASS_GRID_DATA_ERROR:
      return {
        ...state,
        brassGridData: {
          ...state.brassGridData,
          error: true,
          fetching: false,
        },
      };

    case FETCH_BRASS_DETAIL_STARTED:
      return {
        ...state,
        brassDetailData: {
          ...state.brassDetailData,
          error: false,
          fetching: true,
        },
      };

    case FETCH_BRASS_DETAIL_RECEIVE:
      return {
        ...state,
        brassDetailData: {
          ...state.brassDetailData,
          detail: action.data,
          error: false,
          fetching: false,
        },
      };

    case FETCH_BRASS_DETAIL_ERROR:
      return {
        ...state,
        brassDetailData: {
          ...state.brassDetailData,
          error: true,
          fetching: false,
        },
      };

    case FETCH_BRASS_MP3_ALBUM_STARTED:
      return {
        ...state,
        brassMp3AlbumData: {
          ...state.brassMp3AlbumData,
          error: false,
          fetching: true,
        },
      };

    case FETCH_BRASS_MP3_ALBUM_RECEIVE:
      return {
        ...state,
        brassMp3AlbumData: {
          ...state.brassMp3AlbumData,
          detail: action.data,
          error: false,
          fetching: false,
        },
      };

    case FETCH_BRASS_MP3_ALBUM_ERROR:
      return {
        ...state,
        brassMp3AlbumData: {
          ...state.brassMp3AlbumData,
          error: true,
          fetching: false,
        },
      };

    case FETCH_BRASS_SERIES_STARTED:
      return {
        ...state,
        brassSeriesData: {
          ...state.brassSeriesData,
          error: false,
          fetching: true,
        },
      };

    case FETCH_BRASS_SERIES_RECEIVE: {
      const seriesData = formatSeriesData(action.data);
      return {
        ...state,
        brassSeriesData: {
          detail: seriesData,
          error: false,
          fetching: false,
        },
      };
    }

    case FETCH_BRASS_SERIES_ERROR:
      return {
        ...state,
        brassSeriesData: {
          ...state.brassSeriesData,
          error: true,
          fetching: false,
        },
      };

    case RESET_BRASS_DETAIL:
      return {
        ...state,
        brassDetailData: {
          ...state.brassDetailData,
          detail: initialState.brassDetailData.detail,
        },
      };

    case RESET_BRASS_MP3_ALBUM:
      return {
        ...state,
        brassMp3AlbumData: {
          ...state.brassMp3AlbumData,
          detail: undefined,
        },
      };

    case FILTER_BRASS: {
      const brassFilters = getNewBrassFilters(state, action);
      return {
        ...state,
        brassFilters,
        brassGridData: {
          ...state.brassGridData,
          detail: sortData(
            state.brassSort,
            filterData(brassFilters, state.brassGridData.full),
          ),
        },
      };
    }

    case FILTER_BRASS_PRIME: {
      const brassFilters = getNewBrassFilters(state, action);
      return {
        ...state,
        brassFilters,
      };
    }

    case FILTER_BRASS_EXECUTE:
      return {
        ...state,
        brassGridData: {
          ...state.brassGridData,
          detail: sortData(
            state.brassSort,
            filterData(state.brassFilters, state.brassGridData.full),
          ),
        },
      };

    case FILTER_BRASS_RESET: {
      const resetBrassFilters = getResetBrassFilters();
      const resetSort = 'default';
      return {
        ...state,
        brassDetailData: {
          ...state.brassDetailData,
          index: 0,
        },
        brassFilters: resetBrassFilters,
        brassGridData: {
          ...state.brassGridData,
          detail: sortData(
            resetSort,
            filterData(resetBrassFilters, state.brassGridData.full),
          ),
        },
        brassSort: resetSort,
      };
    }

    case SORT_BRASS: {
      return {
        ...state,
        brassGridData: {
          ...state.brassGridData,
          detail: sortData(action.brassSort, state.brassGridData.detail),
        },
        brassSort: action.brassSort,
      };
    }

    case SORT_BRASS_PRIME: {
      return {
        ...state,
        brassSort: action.brassSort,
      };
    }

    case SORT_BRASS_EXECUTE:
      return {
        ...state,
        brassGridData: {
          ...state.brassGridData,
          detail: sortData(state.brassSort, state.brassGridData.detail),
        },
      };

    case UPDATE_BRASS_DETAIL_INDEX:
      return {
        ...state,
        brassDetailData: {
          ...state.brassDetailData,
          index: action.index,
        },
      };

    case FETCH_BRASS_PUBLICATION_DATA_STARTED:
      return {
        ...state,
        brassPublicationData: {
          ...state.brassPublicationData,
          error: false,
          fetching: true,
        },
      };

    case FETCH_BRASS_PUBLICATION_DATA_RECEIVE: {
      const brassPublicationData = preparePublicationData(
        state.brassGridData.detail,
      );
      return {
        ...state,
        brassPublicationData: {
          detail: brassPublicationData,
          error: false,
          fetching: false,
        },
      };
    }

    case FETCH_BRASS_PUBLICATION_DATA_ERROR:
      return {
        ...state,
        brassPublicationData: {
          ...state.brassPublicationData,
          error: true,
          fetching: false,
        },
      };

    case FETCH_BRASS_SERIES_DETAIL_STARTED:
      return {
        ...state,
        brassSeriesDetailData: {
          ...state.brassSeriesDetailData,
          error: false,
          fetching: true,
        },
      };

    case FETCH_BRASS_SERIES_DETAIL_RECEIVE:
      return {
        ...state,
        brassSeriesDetailData: {
          ...state.brassSeriesDetailData,
          detail: action.data,
          error: false,
          fetching: false,
        },
      };

    case FETCH_BRASS_SERIES_DETAIL_ERROR:
      return {
        ...state,
        brassSeriesDetailData: {
          ...state.brassSeriesDetailData,
          error: true,
          fetching: false,
        },
      };

    case RESET_BRASS_SERIES_DETAIL:
      return {
        ...state,
        brassSeriesDetailData: {
          ...state.brassSeriesDetailData,
          detail: initialState.brassSeriesDetailData.detail,
        },
      };

    default:
      return state;
  }
}

function prepareData(brassData: IMinBrassData): IBrassRow[] {
  if (brassData === undefined) {
    return [];
  }

  return brassData.pb.map((publication) => {
    return {
      arranger: getArrangerCellContent(publication, brassData),
      composer: getComposerCellContent(publication, brassData),
      mp3s: getMp3sCellContent(publication),
      number: getNumberCellContent(publication),
      pieceId: publication.p,
      publicationId: publication.id,
      scoreNotes: getScoreNotesCellContent(publication),
      series: getSeriesCellContent(publication, brassData),
      sheetMusic: getSheetMusicCellContent(publication),
      title: getTitleCellContent(publication, brassData),
      type: getTypeCellContent(publication, brassData),
      video: getVideoCellContent(publication, brassData),
      year: getYearCellContent(publication),
    };
  });
}

function prepareHallOfFameData(brassData: IMinBrassData): BrassHallOfFameRow[] {
  if (brassData === undefined) {
    return [];
  }

  return uniqBy(brassData.pb, 'p').map((publication): BrassHallOfFameRow => {
    return new BrassHallOfFameRow({
      arranger: getArrangerCellContent(publication, brassData),
      composer: getComposerCellContent(publication, brassData),
      pieceId: publication.p,
      series: getSeriesCellContent(publication, brassData),
      title: getTitleCellContent(publication, brassData),
      type: getTypeCellContent(publication, brassData),
      year: parseInt(getYearCellContent(publication), 10),
    });
  });
}

function prepareChristmasCollectionData(
  brassData: IMinBrassData,
): ISimplePublicationData[] {
  if (brassData === undefined) {
    return [];
  }

  return brassData.pb
    .filter(
      (publication: IMinBrassPublication) =>
        publication.s === 46 && !!publication.n,
    )
    .map((publication: IMinBrassPublication) => ({
      number: getNumberCellContent(publication),
      title: getTitleCellContent(publication, brassData),
    }));
}

function preparePublicationData(
  brassData: IBrassRow[],
): IBrassPublicationRow[] {
  if (!brassData.length) {
    return [];
  }

  return brassData.map((brassRow: IBrassRow) => {
    const {arranger, composer, series, number, pieceId, publicationId, title} =
      brassRow;

    return new BrassPublicationRow({
      arranger,
      composer,
      series,
      number,
      pieceId,
      publicationId,
      title,
    });
  });
}

function getVideoCellContent(
  publication: IMinBrassPublication,
  brassData: IMinBrassData,
): boolean {
  const pieceId = publication.p;
  const piece = brassData.p[pieceId];

  return !!piece.v;
}

function getScoreNotesCellContent(publication: IMinBrassPublication): boolean {
  return !!publication.no;
}

function getSheetMusicCellContent(publication: IMinBrassPublication): boolean {
  return !!publication.sh;
}

function getMp3sCellContent(publication: IMinBrassPublication): boolean {
  return !!publication.m;
}

function getSeriesCellContent(
  publication: IMinBrassPublication,
  brassData: IMinBrassData,
): string {
  const seriesId = publication.s;
  const series = brassData.s[seriesId];

  return series.n;
}

function getNumberCellContent(publication: IMinBrassPublication): string {
  return publication.n;
}

function getTypeCellContent(
  publication: IMinBrassPublication,
  brassData: IMinBrassData,
): string {
  const pieceId = publication.p;
  const piece = brassData.p[pieceId];
  const typeIds = piece.t;

  const typeNames = typeIds
    .map((typeId: number) => {
      return brassData.t[typeId].n;
    })
    .sort();

  return typeNames.join(', ');
}

function getTitleCellContent(
  publication: IMinBrassPublication,
  brassData: IMinBrassData,
): string {
  const pieceId = publication.p;
  const piece = brassData.p[pieceId];

  return piece.tt;
}

function getComposerCellContent(
  publication: IMinBrassPublication,
  brassData: IMinBrassData,
): string {
  const pieceId = publication.p;
  const piece = brassData.p[pieceId];
  const composerId = piece.c;
  const composer = brassData.c[composerId];

  return composer.n;
}

function getArrangerCellContent(
  publication: IMinBrassPublication,
  brassData: IMinBrassData,
): string {
  const pieceId = publication.p;
  const piece = brassData.p[pieceId];

  if (!piece.a) {
    return '';
  }

  const arrangerId = piece.a;
  const arranger = brassData.c[arrangerId];

  return arranger.n;
}

function getYearCellContent(publication: IMinBrassPublication): string {
  return `${publication.y}`;
}

function getNewBrassFilters(
  state: IBrassState,
  action: IAction,
): IBrassFilters {
  const rval = state.brassFilters;
  rval[action.key] = action.value;

  return rval;
}

function getResetBrassFilters(): IBrassFilters {
  return {
    composer: '',
    media: false,
    number: '',
    series: '',
    sheetMusic: false,
    title: '',
    type: '',
    year: '',
  };
}

function filterData(
  brassFilters: IBrassFilters,
  brassGridData: IBrassRow[],
): IBrassRow[] {
  return brassGridData.filter((row: IBrassRow) => {
    return Object.entries(row).every(([key, value]) => {
      const brassFilter = brassFilters[key];

      switch (key) {
        case 'audio':
        case 'video':
          return brassFilters.media ? !!(row.mp3s || row.video) : true;

        case 'series':
          return brassFilter !== '' ? brassFilter === value : true;

        case 'number':
          if (brassFilter && value === null) {
            return false;
          }

          if (typeof brassFilter !== 'string' || typeof value !== 'string') {
            return true;
          }

          // Handle range requests
          if (brassFilter[0] === '>' || brassFilter[0] === '<') {
            if (value === '') {
              return false;
            }

            const brassFilterNum =
              brassFilter[1] === '='
                ? parseInt(brassFilter.substring(2), 10)
                : parseInt(brassFilter.substring(1), 10);
            const num = parseInt(value, 10);
            if (isNaN(brassFilterNum) || isNaN(num)) {
              return true;
            }

            if (brassFilter.substring(0, 2) === '>=') {
              return num >= brassFilterNum;
            } else if (brassFilter.substring(0, 2) === '<=') {
              return num <= brassFilterNum;
            } else if (brassFilter[0] === '>') {
              return num > brassFilterNum;
            } else return num < brassFilterNum;
          }

          return textCompare(value, brassFilter);

        case 'type':
          if (typeof brassFilter !== 'string' || typeof value !== 'string') {
            return true;
          }

          const types = brassFilter.split('||').map((type) => type.trim());
          return types.length
            ? types.some((type) => textCompare(value, type))
            : textCompare(value, brassFilter);

        case 'title':
          return typeof brassFilter === 'string' && typeof value === 'string'
            ? textCompare(value, brassFilter)
            : true;

        case 'year':
          if (typeof brassFilter !== 'string' || typeof value !== 'string') {
            return true;
          }

          // Handle range requests
          if (brassFilter[0] === '>' || brassFilter[0] === '<') {
            if (value === '') {
              return false;
            }

            const brassFilterNum =
              brassFilter[1] === '='
                ? parseInt(brassFilter.substring(2), 10)
                : parseInt(brassFilter.substring(1), 10);
            const yearNum = parseInt(value, 10);
            if (isNaN(brassFilterNum) || isNaN(yearNum)) {
              return true;
            }

            if (brassFilter.substring(0, 2) === '>=') {
              return yearNum >= brassFilterNum;
            } else if (brassFilter.substring(0, 2) === '<=') {
              return yearNum <= brassFilterNum;
            } else if (brassFilter[0] === '>') {
              return yearNum > brassFilterNum;
            } else return yearNum < brassFilterNum;
          }

          return textCompare(value, brassFilter);

        case 'composer':
          return typeof brassFilter === 'string' && typeof value === 'string'
            ? textCompare(value, brassFilter) ||
                textCompare(row.arranger, brassFilter)
            : true;

        case 'sheetMusic':
          return brassFilters.sheetMusic ? !!row.sheetMusic : true;

        default:
          return true;
      }
    });
  });
}

function sortData(
  brassSort: BrassSort,
  brassGridData: IBrassRow[],
): IBrassRow[] {
  if (brassSort === 'default') {
    return brassGridData;
  }

  const matches = brassSort.match(/^(.+)(Asc|Desc)$/);
  if (!matches) {
    return brassGridData;
  }

  const field = matches[1];
  const sortOrder = matches[2].toLowerCase();

  return [...brassGridData].sort((a: IBrassRow, b: IBrassRow) => {
    let valA: any = a[field];
    let valB: any = b[field];

    // Ignore accents when sorting
    if (typeof valA === 'string') {
      valA = removeAccents(valA);
    }
    if (typeof valB === 'string') {
      valB = removeAccents(valB);
    }

    if (field === 'number') {
      // Unnumbered pieces should always come last
      if (valA === null) {
        return 1;
      }
      if (valB === null) {
        return -1;
      }

      // Handle 2-part piece numbers
      valA = parseFloat((valA as string).replace('-', '.'));
      valB = parseFloat((valB as string).replace('-', '.'));

      // Handle string numbering (i.e. AIES)
      if (isNaN(valA) || isNaN(valB)) {
        valA = a[field];
        valB = b[field];
      }
    }

    if (field === 'year') {
      // Handle unknown years
      if (!valA) {
        return 1;
      }
      if (!valB) {
        return -1;
      }
    }

    const result = valA > valB ? 1 : valA < valB ? -1 : 0;
    return sortOrder === 'asc' ? result : result * -1;
  });
}

function formatSeriesData(
  seriesData: IMinBrassSeriesData,
): IBrassSeriesDropdown[] {
  if (seriesData === undefined) {
    return [];
  }

  const dropdownData = Object.entries(seriesData.p).map(([key, publisher]) => {
    const series = Object.values(seriesData.s).filter((serie) => {
      return serie.p === parseInt(key, 10);
    });
    const sortedSeries = [...series].sort(sortBy('n'));

    return {
      name: publisher.n,
      series: sortedSeries.map((serie) => serie.n),
    };
  });

  return [...dropdownData].sort(sortBy('name'));
}
